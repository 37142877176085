import { Component, OnInit } from "@angular/core";
import { Router, Route, ActivatedRoute } from "@angular/router";
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";
import { Meta, Title } from "@angular/platform-browser";
import { TrackService } from "src/app/services/track.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  user: User;
  errorString: string;
  isAgreeLegal: boolean;
  isAgreeLegalSocial: boolean;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private metaService: Meta,
    private titleService: Title,
    private trackService: TrackService
  ) {
    this.user = {} as User;
  }

  ngOnInit() {
    this.errorString = "";
    this.titleService.setTitle("Register");
    this.metaService.updateTag({
      name: "description",
      content: "Get started managing your content security policy today",
    });

    if (this.route.snapshot.queryParamMap.get("redirect")) {
      localStorage.setItem("redirect", this.route.snapshot.queryParamMap.get("redirect"));
    }
  }

  register() {
    this.userService.register(this.user).subscribe(
      () => {
        if (this.userService.hasRedirect() && this.userService.getRedirect() != "/") {
          console.log("after register, user has redirect", this.userService.getRedirect());
          this.userService.followRedirect();
          return;
        }

        console.log("after redirect user does not have redirect");
        this.router.navigate(["/new"]);
      },
      (e) => {
        // console.log("err", e.error, e);
        this.errorString = e;
      }
    );
  }
}
