import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Plan } from "src/app/models/plan";
import { BillingService } from "src/app/services/billing.service";
import { TrackService } from "src/app/services/track.service";

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.css"],
})
export class PricingComponent implements OnInit {
  plans: Plan[];

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private billingService: BillingService,
    private router: Router,
    private trackService: TrackService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Pricing");
    this.metaService.updateTag({
      name: "description",
      content: "Pricing plans for Csper. Csper plans include Personal, Basic, Business and Enterprise, and they all include the same features.",
    });
    // this.trackService.enableFS();

    this.billingService.getPlans().subscribe((plans) => {
      this.plans = plans;
    });
  }

  selectPlan(plan: string) {
    this.router.navigate(["new"], { queryParams: { plan: plan } });
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  getPlanByTier(tier: string): Plan {
    const duration = "month";
    const plan = this.plans
      ? this.plans.filter((plan) => plan.tier.toLocaleLowerCase() == tier.toLocaleLowerCase() && plan.duration == duration)[0]
      : ({ price: "", duration: "" } as unknown as Plan);
    return plan;
  }
}
