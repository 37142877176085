<div class="container pt-65px">
  <h2>New Organization</h2>

  <div class="alert alert-danger" *ngIf="errorStr">{{ errorStr }}</div>

  <div class="form-group">
    <label><strong>Organization Name</strong></label>
    <input
      class="form-control"
      [(ngModel)]="organization.name"
      placeholder="My Organization"
      required
      minlength="1"
      [class.is-valid]="organization.name"
      [class.is-invalid]="!organization.name"
    />
  </div>

  <div class="form-group">
    <label><strong>Select Plan</strong></label>

    <ng-container>
      <app-pricing-panels (onPlanSelected)="selectPlan($event)"></app-pricing-panels>
    </ng-container>

    <small *ngIf="organization.planName != 'SANDBOX'" class="form-text text-muted"
      ><a routerLink="/pricing">Plan Info</a> The trial period lasts 14 days. Billing will start at the end of 14 days. You can cancel anytime.</small
    >
  </div>
</div>
<app-wave-one></app-wave-one>