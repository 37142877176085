import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-rapidsec",
  templateUrl: "./rapidsec.component.html",
  styleUrls: ["./rapidsec.component.css"],
})
export class RapidsecComponent implements OnInit {
  features: any[];

  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.titleService.setTitle("Product Comparison: RapidSec vs Csper");
    this.metaService.updateTag({
      name: "description",
      content: "Feature comparison between RapidSec and Csper, two content security policy report-uri endpoints",
    });

    this.features = [
      { feature: "Free Trial", rapidsec: true, csper: true },
      { feature: "Policy Building Tools", rapidsec: true, csper: true },
      { feature: "Automatic Policy Generator", rapidsec: false, csper: true },
      { feature: "report-uri Endpoint", rapidsec: true, csper: true },
      { feature: "Custom DNS report-uri", rapidsec: false, csper: true },
      { feature: "Realtime Dashboard", rapidsec: false, csper: true },
      { feature: "Basic CSP Analysis Tools", rapidsec: true, csper: true },
      { feature: "Advance CSP Analysis Tools", rapidsec: false, csper: true },
      { feature: "Report Classification and Filtering", rapidsec: false, csper: true },
      { feature: "Project Management", rapidsec: true, csper: true },
      { feature: "Organizational Management", rapidsec: false, csper: true },
      { feature: "API", rapidsec: true, csper: true },
      { feature: "Simple Alerting", rapidsec: true, csper: true },
      { feature: "Advance Alerting Filters", rapidsec: false, csper: true },
      { feature: "Browser Age Filtering", rapidsec: false, csper: true },
      { feature: "Support", rapidsec: true, csper: true },
    ];
  }
}
