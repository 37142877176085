import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Recommendations, Evaluation, BuilderRecommendations } from "../models/recommendations";
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: "root",
})
export class RecommendationService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  // Deprecated
  getRecommendations(projectID: string, policyID: string): Observable<Recommendations> {
    return this.http.get<Recommendations>(`${this.config.getOrigin()}/api/projects/${projectID}/policies/${policyID}/recommendations`);
  }

  applyRecommendationUpdates(rec: Recommendations): Observable<Recommendations> {
    return this.http.put<Recommendations>(`${this.config.getOrigin()}/api/projects/${rec.projectID}/policies/${rec.policyID}/recommendations`, rec);
  }

  // Public Evaluations
  evaluate(evaluation: Evaluation): Observable<Evaluation> {
    return this.http.post<Evaluation>(`${this.config.getOrigin()}/api/evaluations`, evaluation);
  }

  getEvaluation(evaluationID: string): Observable<Evaluation> {
    return this.http.get<Evaluation>(`${this.config.getOrigin()}/api/evaluations/${evaluationID}`);
  }

  getEvaluations(): Observable<Evaluation[]> {
    return this.http.get<Evaluation[]>(`${this.config.getOrigin()}/api/evaluations`);
  }

  // Project Evaluations
  getPolicyRecommendations(projectID: string, policyID: string): Observable<Evaluation> {
    return this.http.get<Evaluation>(`${this.config.getOrigin()}/api/projects/${projectID}/policies/${policyID}/recommendations/policy`);
  }

  getBuilderRecommendations(projectID: string, policyID: string): Observable<BuilderRecommendations> {
    return this.http.get<BuilderRecommendations>(`${this.config.getOrigin()}/api/projects/${projectID}/policies/${policyID}/recommendations/builder`);
  }

  getAllAcceptedBuilderRecommendations(projectID: string, policyID: string, reportHash: string): Observable<BuilderRecommendations> {
    return this.http.get<BuilderRecommendations>(
      `${this.config.getOrigin()}/api/projects/${projectID}/policies/${policyID}/recommendations/builder?acceptAll=true&reportHash=${reportHash}`
    );
  }

  applyBuilderRecommendations(rec: BuilderRecommendations): Observable<BuilderRecommendations> {
    return this.http.put<BuilderRecommendations>(
      `${this.config.getOrigin()}/api/projects/${rec.projectID}/policies/${rec.policyID}/recommendations/builder`,
      rec
    );
  }
}
