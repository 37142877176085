import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-placeholder",
  templateUrl: "./placeholder.component.html",
  styleUrls: ["./placeholder.component.css"],
})
export class PlaceholderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  scroll(id) {
    let testElement = document.getElementById(id);
    if (testElement != undefined) testElement.scrollIntoView();
  }
}
