<div class="pt-65px">
  <div class="container pt-5">
    <div class="row">
      <div class="col-md-6">
        <h1 class="display-4">Policy Evaluator<span class="badge text-primary" style="font-size: 14px;vertical-align: top">Free</span></h1>
        <p class="lead">Evaluate your website's Content Security Policy for security misconfigurations and recommendations:</p>

        <form (submit)="scan()">
          <div class="form-group">
            <input class="form-control" [(ngModel)]="evaluation.URL" name="url" placeholder="https://website.com" />
          </div>

          <div class="form-check" style="display: inline">
            <input [(ngModel)]="evaluation.isHidden" name="hidden" class="form-check-input" type="checkbox" value="false" />
            <label class="form-check-label"> Hide from recent scans </label>
          </div>

          <button type="submit" class="btn btn-primary float-right">Scan Website</button>
          <div *ngIf="errorStr" class="text-danger">
            {{ errorStr }}
            <div class="card text-dark bg-opacity mt-5 border-0 mx-auto">
              <div class="card-body">
                <h5 class="card-title">Do you want to build a policy for this domain?</h5>
                <div class="float-md-right p-0 d-flex justify-content-center">
                  <button class="btn btn-secondary btn-lg" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }" role="button">
                    Start Building
                  </button>
                  <br />
                </div>
                <p class="card-text pt-1">Csper makes building out content-security-policy easier than ever.</p>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-md-6">
        <div class="text-center pt-3">
          <img src="https://assets.csper.io/evaluator-demo.webp" class="img-fluid rounded shadow" alt="Content Security Policy CSP Evaluator" />
          <p><small>Sample Evaluator Results</small></p>
        </div>
      </div>
    </div>
  </div>
  <app-wave-one></app-wave-one>
  <div class="p-4"></div>
  <div class="container">
    <h2 class="text-center pb-2">View Recent Scans</h2>

    <div class="row">
      <div class="col-md-3 overflow-hidden" *ngFor="let eval of previous">
        <a [routerLink]="['/evaluations', eval.id]">{{ eval.URL }}</a>
      </div>
      <!-- <ul>
          <li *ngFor="let eval of previous">
              <a [routerLink]="['/evaluations', eval.id]">{{eval.URL}}</a>
          </li>
      </ul> -->
    </div>
  </div>

  <div class="py-5"></div>

  <div class="container bg-opacity p-4">
  
        <h2 class="text-center">What is Evaluator?</h2>
        <p>
          Evaluator is a free online tool for scanning and analyzing the content security policy of any website. It looks for security misconfigurations and gives recommendations.
        </p>

        <p>
          Evaluator makes an HTTP request to the specified webserver and grabs any policies in the Content-Security-Policy or
          Content-Security-Policy-Report-Only headers or meta tag.
        </p>

        <p>The policy is then parsed and recommendations are generated.</p>
    </div>
  <div class="pt-4"></div>
  <div class="container py-4">
        <h2 class="text-center">What does it look for</h2>
        <p class="text-center">Evaluator currently supports 30+ unique tests including:</p>
      <ul>
        <div class="row">

      <div class="col-md-6">
        
          <li class="p-1 list-group-item border-0">Unknown CSP Directives</li>
          <li class="p-1 list-group-item border-0">Unknown CSP Source Expressions</li>
          <li class="p-1 list-group-item border-0">Usage of unsafe keywords</li>
          <li class="p-1 list-group-item border-0">Missing quotes on keyword</li>
          <li class="p-1 list-group-item border-0">Invalid use of IP address</li>
          <li class="p-1 list-group-item border-0">Missing Best Practices</li>
      </div>
      <div class="col-md-6">
          <li class="p-1 list-group-item border-0">Duplicate Entries</li>
          <li class="p-1 list-group-item border-0">Parsing Issues</li>
          <li class="p-1 list-group-item border-0">Unrestrictive source on high impact directives</li>
          <li class="p-1 list-group-item border-0">Missing high impact directives</li>
          <li class="p-1 list-group-item border-0">Short nonces</li>
          <li class="p-1 list-group-item border-0">And more</li>
      </div>
    </div>

    </ul>
  </div>
  <app-footer> </app-footer>
</div>
