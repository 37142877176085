import { Component, Input, OnInit } from "@angular/core";
import { Organization } from "src/app/models/organization";

@Component({
  selector: "app-org-project-selector",
  templateUrl: "./org-project-selector.component.html",
  styleUrls: ["./org-project-selector.component.css"],
})
export class OrgProjectSelectorComponent implements OnInit {
  @Input() currentID: string;
  @Input() currentName: string;
  @Input() organizations: Organization[];

  searchStr = "";

  constructor() {}

  ngOnInit(): void {}
}
