import { Component, OnInit } from "@angular/core";
import { Page } from "src/app/models/cms";
import { Title, Meta, DomSanitizer } from "@angular/platform-browser";
import { PageService } from "src/app/services/page.service";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.css"],
})
export class BlogComponent implements OnInit {
  posts: Page[];
  mainBlogSlug: string;
  mainBlog: Page;
  selectedBlogSlugs: string[];
  selectedBlogs: Page[];
  blogPostCategories: string[];

  constructor(private pageService: PageService, private titleService: Title, private metaService: Meta, private sanitizer: DomSanitizer ) {
    this.mainBlogSlug = "csp-nodejs-express-csper";
    this.selectedBlogSlugs = ["no-more-unsafe-inline", "realtime-xss-detection", "csp-violates-the-content-security-policy-directive"];
  }

  ngOnInit() {
    this.titleService.setTitle("Csper Blog");
    this.metaService.updateTag({
      name: "description",
      content:
        "Stay up to date on the latest and greatest of csper developments. This includes new tools, features, and products related to content-securty-policy and report-uri.",
    });
    
      
    this.pageService.getBlogPosts().subscribe((posts) => {
      posts.sort((a, b) => {
        // @ts-ignore
        return new Date(b.ts) - new Date(a.ts);
      });
      this.mainBlog = posts.find(p => p.slug === this.mainBlogSlug);
      this.selectedBlogs = posts.filter(post => this.selectedBlogSlugs.includes(post.slug));
      this.posts = posts.filter(post => !this.selectedBlogSlugs.includes(post.slug) && post.slug !== this.mainBlogSlug);
      this.blogPostCategories = [...new Set(posts.map(p => p.category))];
    });
  }

  shortDescription(desc: string) {
    return desc.length <= 125 ? desc : desc.substring(0, 125)+'...'
  }
}
