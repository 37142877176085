import { Component, OnInit, HostListener } from "@angular/core";
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { TrackService } from "src/app/services/track.service";
import { ProjectService } from "src/app/services/project.service";
import { Overview } from "src/app/models/overview";
import { Subscription } from "rxjs";
import { TableOfContents } from "src/app/models/cms";
import { PageService } from "src/app/services/page.service";

@Component({
  selector: "app-header-nav",
  templateUrl: "./header-nav.component.html",
  styleUrls: ["./header-nav.component.css"],
})
export class HeaderNavComponent implements OnInit {
  me: User;
  meSub: Subscription;

  showNav: boolean = false;
  projectID: string;

  isScrolled: boolean;
  inDashboard: boolean;
  inEvaluator: boolean;
  inDocs: boolean;
  inBlog: boolean;

  tocs: TableOfContents[];

  constructor(
    private userService: UserService,
    private router: Router,
    private trackService: TrackService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private pageService: PageService
  ) {
    this.projectID = "";
    this.isScrolled = false;
    this.inDashboard = false;
    this.inEvaluator = false;
    this.inDocs = false;
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 100) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  ngOnInit() {
    this.pageService.getDocsTOC().subscribe((tocs) => {
      this.tocs = tocs;
    });

    if (window.location.pathname.toString().startsWith("/p/") || window.location.pathname.toString().startsWith("/org/")) {
      this.inDashboard = true;
    } else {
      this.inDashboard = false;
    }

    if (window.location.pathname.toString().startsWith("/evaluator")) {
      this.inEvaluator = true;
    } else {
      this.inEvaluator = false;
    }

    if (window.location.pathname.toString().startsWith("/docs")) {
      this.inDocs = true;
    } else {
      this.inDocs = false;
    }

    if (window.location.pathname.toString().startsWith("/blog")) {
      this.inBlog = true;
    } else {
      this.inBlog = false;
    }

    // super nasty
    this.router.events.subscribe((e: any) => {
      if (e.url && e.url.startsWith("/p/")) {
        this.projectID = e.url.substring(3, 27);
      }

      if (window.location.pathname.toString().startsWith("/p/") || window.location.pathname.toString().startsWith("/org/")) {
        this.inDashboard = true;
      } else {
        this.inDashboard = false;
      }

      if (window.location.pathname.toString().startsWith("/evaluator")) {
        this.inEvaluator = true;
      } else {
        this.inEvaluator = false;
      }

      if (window.location.pathname.toString().startsWith("/docs")) {
        this.inDocs = true;
      } else {
        this.inDocs = false;
      }

      if (window.location.pathname.toString().startsWith("/blog/")) {
        this.inBlog = true;
      } else {
        this.inBlog = false;
      }
    });

    this.meSub = this.userService.user$.subscribe((user) => {
      this.me = user;
    });
  }

  ngOnDestroy() {
    this.meSub.unsubscribe();
  }
}
