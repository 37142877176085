<div class="pt-65px"></div>

<div class="container" *ngIf="post">
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <h1>{{ post.title }}</h1>
      <div class="clearfix">
        <p class="card-text mb-0 float-right">
          <small class="text-muted">{{ post.ts | timeAgo }}</small>
        </p>
        <p class="card-text mb-0">
          <small class="text-muted">Stuart Larsen #{{ post.category }}</small>
        </p>
      </div>
      <hr />

      <app-content [contents]="post.content"></app-content>
    </div>
    <div class="col-md-2"></div>
  </div>
</div>
<br />

<app-footer *ngIf="post"></app-footer>
