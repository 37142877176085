import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Alert, AlertEvent } from "../models/alert";
import { ConfigService } from "./config.service";
import { Profile } from "../models/user";
import { Organization } from "../models/organization";
import { BillingOverage } from "../models/billing";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  getProfiles(search: string, page: number): Observable<Profile[]> {
    return this.http.get<Profile[]>(`${this.config.getOrigin()}/api/admin/profiles?page=${page}&search=${search}`);
  }

  getProfile(id: string): Observable<Profile> {
    return this.http.get<Profile>(`${this.config.getOrigin()}/api/admin/profiles/${id}`);
  }

  purgeProfile(id: string): Observable<any> {
    return this.http.delete(`${this.config.getOrigin()}/api/admin/profiles/${id}/purge`, { responseType: "text" });
  }

  getOrganizations(): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${this.config.getOrigin()}/api/admin/organizations`);
  }

  purgeOrganization(id: string): Observable<any> {
    return this.http.delete(`${this.config.getOrigin()}/api/admin/organizations/${id}/purge`, { responseType: "text" });
  }

  closeOrganization(id: string): Observable<any> {
    return this.http.delete(`${this.config.getOrigin()}/api/admin/organizations/${id}/close`, { responseType: "text" });
  }

  purgeProject(id: string): Observable<any> {
    return this.http.delete(`${this.config.getOrigin()}/api/admin/projects/${id}/purge`, { responseType: "text" });
  }

  getBillingOverages(): Observable<BillingOverage[]> {
    return this.http.get<BillingOverage[]>(`${this.config.getOrigin()}/api/admin/billing/overages`);
  }

  // newAlert(alert: Alert): Observable<Alert> {
  //     return this.http.post<Alert>(`${this.config.getOrigin()}/api/projects/${alert.projectID}/alerts`, alert)
  // }

  // getAlerts(projectID: string): Observable<Alert[]> {
  //     return this.http.get<Alert[]>(`${this.config.getOrigin()}/api/projects/${projectID}/alerts`)
  // }

  // getAlert(projectID, alertID: string): Observable<Alert> {
  //     return this.http.get<Alert>(`${this.config.getOrigin()}/api/projects/${projectID}/alerts/${alertID}`)
  // }

  // updateAlert(alert: Alert): Observable<Alert> {
  //     return this.http.put<Alert>(`${this.config.getOrigin()}/api/projects/${alert.projectID}/alerts/${alert.id}`, alert)
  // }

  // deleteAlert(alert: Alert): Observable<any> {
  //     return this.http.delete<any>(`${this.config.getOrigin()}/api/projects/${alert.projectID}/alerts/${alert.id}`)
  // }

  // getAlertEvents(projectID: string): Observable<AlertEvent[]> {
  //     return this.http.get<AlertEvent[]>(`${this.config.getOrigin()}/api/projects/${projectID}/alerts/events`)
  // }

  // sendSampleEvent(projectID, alertID: string): Observable<AlertEvent> {
  //     return this.http.post<AlertEvent>(`${this.config.getOrigin()}/api/projects/${projectID}/alerts/${alertID}/sample`, null)
  // }

  // acknowledgeAlert(alert: Alert): Observable<AlertEvent> {
  //     return this.http.put<AlertEvent>(`${this.config.getOrigin()}/api/projects/${alert.projectID}/alerts/events/${alert.id}/acknowledge`, alert)
  // }

  // acknowledgeAllAlerts(projectID: string): Observable<any> {
  //     return this.http.post(`${this.config.getOrigin()}/api/projects/${projectID}/alerts/events/acknowledgeAll`, "", { responseType: 'text' })
  // }
}

// Alerts
// r.HandleFunc("/api/projects/{projectID}/alerts", NewAlertHandler).Methods("POST")
// r.HandleFunc("/api/projects/{projectID}/alerts", GetAlertsHandler).Methods("GET")
// r.HandleFunc("/api/projects/{projectID}/alerts/{alertID}", GetAlertHandler).Methods("GET")
// r.HandleFunc("/api/projects/{projectID}/alerts/{alertID}", UpdateAlertHandler).Methods("POST")

// r.HandleFunc("/api/projects/{projectID}/alerts/events", GetAlertEventsHandler).Methods("GET")
// r.HandleFunc("/api/projects/{projectID}/alerts/events/acknowledgeAll", AcknowledgeAllAlertEventsHandler).Methods("POST")
// r.HandleFunc("/api/projects/{projectID}/alerts/events/{alertEventID}/acknowledge", AcknowledgeAlertEventHandler).Methods("PUT")
