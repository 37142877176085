import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";
import { Meta, Title } from "@angular/platform-browser";
import { TrackService } from "src/app/services/track.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  user: User;
  errorString: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private metaService: Meta,
    private titleService: Title,
    private trackService: TrackService
  ) {
    this.user = {} as User;
  }

  ngOnInit() {
    this.titleService.setTitle("Login");
    this.metaService.updateTag({
      name: "description",
      content:
        "Content-Security-Policy made easy. Csper is a content security policy violation report endpoint. Csper gives website owners insight and recommendations based off the csp policy and reports",
    });
    // this.trackService.enableFS();
  }

  login() {
    this.userService.login(this.user).subscribe(
      () => {
        this.router.navigate(["/organizations"]);
      },
      (error) => {
        this.errorString = error;
      }
    );
  }
}
