import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { PageContent } from "src/app/models/cms";
import { HighlightService } from "src/app/services/highlight.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.css"],
})
export class ContentComponent implements OnInit, OnChanges {
  @Input("contents") inContent: PageContent[];
  contents: PageContent[];

  isHighlighted: boolean;

  constructor(private highlightService: HighlightService, private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  ngOnChanges() {
    for (var contentIndex = 0; contentIndex < this.inContent.length; contentIndex++) {
      if (this.inContent[contentIndex].contentType == "VIDEO") {
        // @ts-ignore
        this.inContent[contentIndex].videoContent.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.inContent[contentIndex].videoContent.url);
      }

      if (this.inContent[contentIndex].contentType == "HTML") {
        // @ts-ignore
        this.inContent[contentIndex].htmlContent.html = this.sanitizer.bypassSecurityTrustHtml(this.inContent[contentIndex].htmlContent.html);
      }
    }

    this.contents = this.inContent;
  }

  ngAfterViewChecked() {
    this.highlightService.highlightAll();
    this.isHighlighted = true;
  }
}
