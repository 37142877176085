<div class="container pt-65px">
  <h1 class="display-4 text-center">Product Comparison: Csper and RapidSec</h1>

  <p class="lead pb-2 text-center">See why over 30,000 websites have trusted Csper with their website security.</p>

  <p>If you're not sure which is best, both RapidSec and Csper have free trials. We highly recommend trying both products to see for yourself.</p>
</div>

<div class="container">
  <h3>Feature Comparison</h3>
  <table class="table">
    <tr>
      <th class="w-50">Feature</th>
      <th class="w-25 text-center">RapidSec</th>
      <th class="w-25 text-center">Csper</th>
    </tr>

    <tr *ngFor="let feature of features">
      <td>{{ feature.feature }}</td>
      <td class="text-center">
        <i-feather *ngIf="feature.rapidsec" class="text-primary feather-check" name="check"></i-feather>
        <i-feather *ngIf="!feature.rapidsec" class="text-danger feather-check" name="x"></i-feather>
      </td>
      <td class="text-center">
        <i-feather *ngIf="feature.csper" class="text-primary feather-check" name="check"></i-feather>
        <i-feather *ngIf="!feature.csper" class="text-danger feather-check" name="x"></i-feather>
      </td>
    </tr>
  </table>
</div>

<app-wave-top></app-wave-top>
<div class="bg-opacity mb-5">
  <div class="container py-5">
    <div class="text-center">
      <h3 class="pb-2">Find out why over 30,000 websites are using Csper</h3>
      <p class="lead mb-0 pt-3">
        <a class="btn btn-secondary btn-lg mr-2 mb-1" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }" role="button"
          >Start Free Trial</a
        >
        <a class="btn btn-outline-primary btn-lg" [routerLink]="['/pricing']" [queryParams]="{ redirect: '/new' }" role="button">View pricing</a>
      </p>
    </div>
  </div>
  <app-wave-three></app-wave-three>
</div>
<app-footer></app-footer>
