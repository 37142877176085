import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, ObservableInput, from, of, Subject, ReplaySubject } from "rxjs";
import { User } from "../models/user";
import { ConfigService } from "./config.service";
import { Router } from "@angular/router";
import { catchError, flatMap, tap } from "rxjs/operators";
import { TrackService } from "./track.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  user$: Subject<User>;
  isLoggedIn$: Subject<boolean>;

  constructor(private http: HttpClient, private config: ConfigService, private router: Router, private trackService: TrackService) {
    this.user$ = new ReplaySubject<User>(1);
    this.isLoggedIn$ = new ReplaySubject<boolean>(1);
    this.loadUser();
  }

  loadUser() {
    this.http.get<User>(`${this.config.getOrigin()}/api/users/me`).subscribe(
      (me) => {
        this.user$.next(me);
        this.isLoggedIn$.next(true);

        let isFirstLogin = false;
        let oneMinuteAgo = new Date().getTime() - 60 * 1000;
        if (new Date(me.ts).getTime() > oneMinuteAgo) {
          isFirstLogin = true;
        }

        this.trackService.identify(me.id, me.email, me.firstName + " " + me.lastName, me.authMechanism, isFirstLogin);

        this.followRedirect();
      },
      () => {
        this.isLoggedIn$.next(false);
      }
    );
  }

  // r.HandleFunc("/api/users/resetPassword", StartResetPasswordHandler).Methods("POST")
  // r.HandleFunc("/api/users/resetPassword/{token}", EndResetPasswordHandler).Methods("POST")

  forgotPassword(user: User) {
    return this.http.post(`${this.config.getOrigin()}/api/users/forgotPassword`, user);
  }

  resetPassword(password: string, token: string) {
    return this.http.post(`${this.config.getOrigin()}/api/users/resetPassword`, { password: password, email: token });
  }

  hasRedirect() {
    return !!localStorage.getItem("redirect");
  }

  getRedirect() {
    return localStorage.getItem("redirect");
  }

  followRedirect() {
    if (localStorage.getItem("redirect")) {
      let redirect = localStorage.getItem("redirect");
      localStorage.removeItem("redirect");
      console.log("Found local storage redirect:", redirect);
      this.router.navigateByUrl(redirect);
    }
  }

  login(user: User): Observable<any> {
    return this.http.post(`${this.config.getOrigin()}/login`, user).pipe(
      tap((me: User) => {
        this.trackService.identify(me.id, me.email, me.firstName + " " + me.lastName, me.authMechanism, false);
        this.user$.next(me);
        this.isLoggedIn$.next(true);
      })
    );
  }

  register(user: User): Observable<any> {
    return this.http.post(`${this.config.getOrigin()}/register`, user).pipe(
      tap((me: User) => {
        this.trackService.identify(me.id, me.email, me.firstName + " " + me.lastName, me.authMechanism, true);
        this.user$.next(me);
        this.isLoggedIn$.next(true);
      })
    );
  }

  logout(): Observable<any> {
    return this.http.get(`${this.config.getOrigin()}/logout`).pipe(
      tap(() => {
        this.user$.next(null);
        this.isLoggedIn$.next(false);
      })
    );
  }

  redirectRegister() {
    localStorage.setItem("redirect", this.router.url);
    this.router.navigate(["register"]);
  }

  redirectLogin() {
    localStorage.setItem("redirect", this.router.url);
    this.router.navigate(["login"]);
  }

  verifySession(): Observable<User> {
    let user = this.user$;
    user.subscribe(
      (me) => {
        if (me == null) {
          this.redirectRegister();
        }
      },
      () => {
        this.redirectRegister();
      }
    );
    return user;
  }
}
