import { Pipe, PipeTransform } from "@angular/core";
import { PolicyService } from "../services/policy.service";
import { Report, ReportGroup } from "../models/report";
import orderBy from "lodash.orderby";

@Pipe({
  name: "reportGroupSort",
})
export class ReportGroupSortPipe implements PipeTransform {
  constructor(private policyService: PolicyService) {}

  transform(arr: ReportGroup[], column: string = ""): ReportGroup[] {
    if (!column || column === "") {
      return arr;
    }

    if (!arr) {
      return arr;
    } // no array

    if (arr.length <= 1) {
      return arr;
    } // array with only one item
    if (!column || column === "") {
      alert("invalid columns");
    }

    var order = "asc";
    if (column == "count" || column == "lastSeen") {
      order = "desc";
    }

    //@ts-ignore
    return orderBy(arr, [column, "blockedURI"], [order, "dec"]);
  }
}
