import { Injectable } from "@angular/core";

import "prismjs";
import "prismjs/plugins/toolbar/prism-toolbar";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard";
import "prismjs/components/prism-css";
import "prismjs/components/prism-json";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-apacheconf";
import "prismjs/components/prism-nginx";

import "prismjs/components/prism-go";
import "prismjs/components/prism-python";

@Injectable({
  providedIn: "root",
})
export class HighlightService {
  constructor() {}

  highlightAll() {
    // @ts-ignore
    Prism.highlightAll();
  }
}
