import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tos",
  templateUrl: "./tos.component.html",
  styleUrls: ["./tos.component.css"],
})
export class TosComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
