import { Component, OnInit } from "@angular/core";
import { AdminService } from "src/app/services/admin.service";
import { Profile } from "src/app/models/user";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-admin-profile-view",
  templateUrl: "./admin-profile-view.component.html",
  styleUrls: ["./admin-profile-view.component.css"],
})
export class AdminProfileViewComponent implements OnInit {
  profile: Profile;
  profileID: string;
  purgeConfirm: boolean;

  constructor(private adminService: AdminService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.profileID = this.route.snapshot.paramMap.get("profileID");

    this.adminService.getProfile(this.profileID).subscribe((profile) => {
      this.profile = profile;
    });
  }

  purgeUser() {
    this.adminService.purgeProfile(this.profileID).subscribe(() => {});
  }

  purgeProject(projectId: string) {
    this.adminService.purgeProject(projectId).subscribe(
      () => {},
      (e) => {
        alert(e);
      }
    );
  }

  purgeOrganization(organizationId: string) {
    this.adminService.purgeOrganization(organizationId).subscribe(
      () => {},
      (e) => {
        alert(e);
      }
    );
  }

  closeOrganization(organizationId: string) {
    this.adminService.closeOrganization(organizationId).subscribe(
      () => {},
      (e) => {
        alert(e);
      }
    );
  }
}
