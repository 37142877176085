<div class="container pt-65px">
    <a [routerLink]="['/docs/content-security-policy']" >
      <div class="card mb-3 m-auto border-0 shadow-sm" style="max-width: 600px" [hover-class]="'shadow-lg'">
        <div class="row no-gutters">
          <div class="col-md-5">
            <img src="https://assets.csper.io/docs.webp" alt="CSP documentation" width="100%" />
          </div>
          <div class="col-md-6">
            <div class="card-body">
              <h5 class="card-title">User Docs</h5>
              <p class="card-text text-dark">Check out Csper's documentation on how to get started with CSP, remove inline resources, report-uri and more.</p>
            </div>
          </div>
          <div class="col-md-1 d-md-flex flex-md-wrap align-items-md-center d-none d-sm-block">
            <i-feather name="chevron-right"></i-feather>
          </div>
        </div>
      </div>
    </a>
    <h3 class="text-center py-3 mt-2">Frequently Asked Questions</h3>
    <app-faqs></app-faqs>
  </div>
  
  <!-- articles -->
  <div class="container">
    <div class="text-center">
      <h2 class="pt-5">Top Articles</h2>
      <p class="pb-3">Not sure what Content Security Policy is? Read the articles on best practices</p>
    </div>
  
    <div class="card-deck">
      <div class="card shadow border-0" [hover-class]="'shadow-lg'">
        <a [routerLink]="['/blog/an-introduction-to-content-security-policy']">
          <div class="card-body content-body-link">
            <img src="https://assets.csper.io/intro-csp-blog-1.webp" alt="CSP nodejs and express" width="100%" />
            <h6 class="card-title pt-2">An Introduction To Content Security Policy</h6>
            <p class="card-text text-muted pt-2 text">
              <small class="text-muted"
                >This article is a very introductory background to Content Security Policy, it is intended for individuals who have some technical
                experience, but have never dug into content security policy.</small
              >
            </p>
          </div>
        </a>
      </div>
      <div class="card shadow border-0" [hover-class]="'shadow-lg'">
        <a [routerLink]="['/blog/csp-nodejs-express-csper']">
          <div class="card-body">
            <img src="https://assets.csper.io/express-nodejs-how-toget-started-height.webp" alt="CSP nodejs and express" width="100%" />
            <h6 class="card-title pt-2 content-body-link">Getting Started with Content Security Policy using NodeJS/Express and Csper</h6>
            <p class="card-text text-muted pt-2">
              <small class="text-muted"
                >This guide covers building and installing content security policy (CSP) on a nodejs express server. This guide covers content security
                policy, generating a content security policy, report-uri, inline scripts, and deployment.</small
              >
            </p>
          </div>
        </a>
      </div>
      <div class="card shadow border-0" [hover-class]="'shadow-lg'">
        <a [routerLink]="['/blog/other-csp-security']">
          <div class="card-body content-body-link">
            <img src="https://assets.csper.io/security-features-csp-1.webp" alt="CSP nodejs and express" width="100%" />
            <h6 class="card-title pt-2">Other Security Features of Content Security Policy</h6>
            <p class="card-text text-muted pt-2">
              <small class="text-muted"
                >Other security features of content security policy including upgrade-insecure-requests, block-all-mixed-content, frame-ancestors,
                sandbox, form-actions, and more.</small
              >
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
  
  <app-wave-top></app-wave-top>
  <div class="bg-opacity mb-5">
    <div class="container py-5">
      <div class="text-center">
        <h3 class="pb-2">Find out why over 30,000 websites are using Csper</h3>
        <p class="lead mb-0 pt-3">
          <a class="btn btn-secondary btn-lg mr-2 mb-1" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }" role="button"
            >Start Free Trial</a
          >
          <a class="btn btn-outline-primary btn-lg" [routerLink]="['/pricing']" [queryParams]="{ redirect: '/new' }" role="button">View pricing</a>
        </p>
      </div>
    </div>
    <app-wave-three></app-wave-three>
  </div>
  <app-footer></app-footer>
  