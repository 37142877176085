import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject, ReplaySubject } from "rxjs";
import { Overview } from "../models/overview";
import { Organization } from "../models/organization";
import { ConfigService } from "./config.service";
import { Feedback } from "../models/feedback";
import { Member } from "../models/project";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  overview$: Subject<Overview>;
  overviewID: string;

  constructor(private http: HttpClient, private config: ConfigService) {}

  getOrganizationsFull(): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${this.config.getOrigin()}/api/organizations/full`);
  }

  getOrganizations(): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${this.config.getOrigin()}/api/organizations`);
  }

  updateOrganization(p: Organization): Observable<Organization> {
    return this.http.put<Organization>(`${this.config.getOrigin()}/api/organizations/${p.id}`, p, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    });
  }

  getOrganization(id: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.config.getOrigin()}/api/organizations/${id}`);
  }

  getOrganizationFull(id: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.config.getOrigin()}/api/organizations/${id}/full`);
  }

  newOrganization(p: Organization): Observable<Organization> {
    return this.http.post<Organization>(`${this.config.getOrigin()}/api/organizations`, p);
  }

  getOrganizationOverview(id: string): Observable<Overview> {
    if (!this.overview$ || id != this.overviewID) {
      this.overview$ = new ReplaySubject<Overview>(1);
      this.overviewID = id;
      this.refreshOverview(id);
    }
    return this.overview$.asObservable();
  }

  refreshOverview(id: string) {
    this.http.get<Overview>(`${this.config.getOrigin()}/api/organizations/${id}/overview`).subscribe((o) => {
      this.overview$.next(o);
    });
  }

  inviteMember(organizationID: string, Member: Member): Observable<Organization> {
    return this.http.post<Organization>(`${this.config.getOrigin()}/api/organizations/${organizationID}/invite`, Member);
  }

  joinOrganization(organizationID, token: string): Observable<Organization> {
    return this.http.post<Organization>(`${this.config.getOrigin()}/api/organizations/${organizationID}/join/${token}`, null);
  }

  sendFeedback(feedback: Feedback): Observable<any> {
    return this.http.post(`${this.config.getOrigin()}/api/feedback`, feedback);
  }

  updateRoles(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(`${this.config.getOrigin()}/api/organizations/${organization.id}/membership`, organization);
  }

  deleteMember(organizationID: string, Member: Member): Observable<Organization> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: Member,
    };

    return this.http.delete<Organization>(`${this.config.getOrigin()}/api/organizations/${organizationID}/membership`, options);
  }

  deleteOrganization(organizationID: string, feedback: Feedback): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: feedback,
    };

    return this.http.delete(`${this.config.getOrigin()}/api/organizations/${organizationID}`, options);
  }

  isOlderThanDay = (organization): boolean => {
    const day = 1000 * 60 * 60 * 24;
    const dayago = Date.now() - day;

    //@ts-ignore
    return new Date(organization.ts) < dayago;
  };
}
