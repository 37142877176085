import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-generator",
  templateUrl: "./generator.component.html",
  styleUrls: ["./generator.component.css"],
})
export class GeneratorComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.titleService.setTitle("Content Security Policy (CSP) Evaluator");
    this.metaService.updateTag({
      name: "description",
      content:
        "Automatically generate Content Security Policy (CSP) in minutes using chrome/firefox extension. Uses Content Security Policy report-uri to construct the policy.",
    });
  }
}
