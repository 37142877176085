<header>
  <nav
    class="navbar-expand-lg navbar-light fixed-top"
    [class.bg-white]="inDashboard || isScrolled || inDocs"
    [class.shadow-lg]="isScrolled && !inDashboard && !inDocs"
    [class.shadow-sm]="inDashboard"
    [class.border-bottom]="inDashboard || inDocs"
    [class.border-light]="inDashboard"
  >
    <div [class.container]="!inDashboard && !inDocs && !inBlog" class="d-flex navbar">
      <a *ngIf="!me" class="navbar-brand" routerLink="/">
        <img src="https://assets.csper.io/csper_128.webp" width="30" height="30" alt="logo" />
        Csper
      </a>

      <a *ngIf="me" class="navbar-brand" routerLink="/organizations">
        <img src="https://assets.csper.io/csper_128.webp" width="30" height="30" alt="logo" />
        Csper
      </a>

      <button
        (click)="showNav = !showNav; isScrolled = true"
        class="navbar-toggler"
        type="button"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse collapse" id="navbarCollapse" [ngClass]="{ show: showNav }">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a (click)="showNav = false" class="nav-link text-dark" routerLink="/pricing">Pricing</a>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-dark" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Products
            </a>
            <div class="dropdown-menu white-space-normal product-dropdown-menu border-0 shadow" style="min-width: 350px">
              <a (click)="showNav = false" class="dropdown-item text-dark" routerLink="/report-uri">
                <p class="m-0 p-0"><strong>Report Collector</strong></p>
                <p class="text-muted m-0 p-0 white-space-normal">
                  <small>CSP report-uri endpoint, with built in classification, aggregation, alerting and more</small>
                </p>
              </a>

              <div class="dropdown-divider"></div>

              <a (click)="showNav = false" class="dropdown-item text-dark" routerLink="/generator">
                <p class="m-0 p-0">
                  <strong>Policy Generator</strong>
                  <span class="badge badge-pill text-primary">Free</span>
                </p>

                <p class="text-muted m-0 p-0 white-space-normal">
                  <small>Automatically generate new content security policies</small>
                </p>
              </a>
              <div class="dropdown-divider"></div>
              <a (click)="showNav = false" class="dropdown-item text-dark" routerLink="/evaluator">
                <p class="m-0 p-0"><strong>Policy Evaluator</strong><span class="badge badge-pill text-primary">Free</span></p>

                <p class="text-muted m-0 p-0 white-space-normal">
                  <small> Evaluate existing content security policies</small>
                </p>
              </a>
            </div>
          </li>

          <li class="nav-item">
            <a (click)="showNav = false" class="nav-link text-dark" routerLink="/blog">Blog</a>
          </li>

          <li class="nav-item">
            <a (click)="showNav = false" class="nav-link text-dark" routerLink="/docs/content-security-policy">Docs</a>
          </li>

          <!-- Collapsed Docs -->
          <li class="nav-item dropdown d-sm-block d-md-none">
            <a
              class="nav-link dropdown-toggle text-dark"
              href="#"
              id="docsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Docs
            </a>
            <div class="dropdown-menu" aria-labelledby="docsDropdown">
              <ng-container *ngFor="let toc of tocs; index as i">
                <a
                  li
                  *ngIf="!toc.isHeading"
                  class="dropdown-item text-dark"
                  [routerLink]="['/docs', toc.id]"
                  routerLinkActive="active"
                  (click)="showNav = false"
                >
                  {{ toc.title }}
                </a>
              </ng-container>
            </div>
          </li>
        </ul>

        <ul class="nav navbar-nav navbar-right">
          <li *ngIf="me" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-dark" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <ng-container *ngIf="me.firstName && me.lastName; else elseBlock">{{ me.firstName }} {{ me.lastName }}</ng-container>
              <ng-template #elseBlock>{{ me.email }}</ng-template>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a (click)="showNav = false" class="dropdown-item text-dark" routerLink="/organizations">Organizations</a>

              <div class="dropdown-divider"></div>
              <a href="/logout" class="dropdown-item text-dark">Logout</a>
            </div>
          </li>

          <li *ngIf="me && projectID" class="nav-item dropdown d-sm-block d-md-none">
            <a class="nav-link dropdown-toggle text-dark" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Current Project
            </a>
            <div class="dropdown-menu">
              <a (click)="showNav = false" class="dropdown-item" [routerLink]="['p', projectID, 'overview']">Overview </a>

              <a (click)="showNav = false" class="dropdown-item" [routerLink]="['p', projectID, 'realtime']">Realtime </a>

              <a (click)="showNav = false" class="dropdown-item" [routerLink]="['p', projectID, 'analysis']">Reports </a>

              <a (click)="showNav = false" class="dropdown-item" [routerLink]="['p', projectID, 'policies']">Policies </a>

              <a (click)="showNav = false" class="dropdown-item" [routerLink]="['p', projectID, 'settings']">Settings </a>
            </div>
          </li>

          <li *ngIf="!me" class="nav-item">
            <a class="nav-link text-dark" routerLink="/login">Login</a>
          </li>

          <form class="form-inline" *ngIf="!me">
            <a class="btn btn-secondary my-2 my-sm-0" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }">Start Free Trial</a>
          </form>
        </ul>
      </div>
    </div>
  </nav>
</header>
