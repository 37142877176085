<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 80">
    <title>background waves</title>
    <g>
      <g id="background_shapes" data-name="background shapes">
        <path
          class="cls-1"
          fill="blue"
          opacity="0.3"
          d="M898.6,0C634.05,0,413,132.84,42.42,132.84c-15.07,0-29.18-.39-42.42-1.12v61.4H1024v-183A775.82,775.82,0,0,0,898.6,0Z"
        />
        <path class="cls-5" fill="white" d="M0,0C157,66.65,379,97.51,713.21,45.76,836.7,26.65,940.52,29.13,1024,36.36V80.73H0Z" />
      </g>
    </g>
  </svg>