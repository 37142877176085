import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ProjectService } from "src/app/services/project.service";
import { Subscription } from "rxjs";
import { OrganizationService } from "src/app/services/organization.service";

@Component({
  selector: "app-join",
  templateUrl: "./join.component.html",
  styleUrls: ["./join.component.css"],
})
export class JoinComponent implements OnInit {
  isLoggedInSub: Subscription;

  projectID: string;
  token: string;
  errorString: string;

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.projectID = this.route.snapshot.paramMap.get("projectID");
    this.token = this.route.snapshot.paramMap.get("token");

    this.isLoggedInSub = this.userService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.projectService.joinProject(this.projectID, this.token).subscribe(
          (p) => {
            this.router.navigate(["/p", this.projectID, "analysis"]);
            return;
          },
          (error) => {
            if (error.indexOf("already a member") >= 0) {
              this.router.navigate(["/p", this.projectID, "analysis"]);
              return;
            }

            this.organizationService.joinOrganization(this.projectID, this.token).subscribe(
              (p) => {
                this.router.navigate(["/org", this.projectID, "projects"]);
                return;
              },
              (error) => {
                if (error.indexOf("already a member") >= 0) {
                  this.router.navigate(["/org", this.projectID, "projects"]);
                  return;
                }

                this.errorString = error;
              }
            );
          }
        );
      } else {
        this.userService.redirectRegister();
      }
    });
  }

  noOnDestroy() {
    this.isLoggedInSub.unsubscribe();
  }
}
