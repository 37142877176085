<div class="container-fluid pt-65px">
  <table class="table table-condensed">
    <tr>
      <th>Organization</th>
      <th>Admins</th>
      <th>Plan</th>
      <th>Reports</th>
      <th>Error</th>

      <th>Notification ID</th>
      <th>Actions</th>
    </tr>
    <tr *ngFor="let overage of billingOverages">
      <td>
        <a [routerLink]="['/admin', 'profiles', overage.profileLink]">{{ overage.organizationName }}</a>
      </td>
      <td>
        <span *ngFor="let email of overage.adminEmails"> {{ email }}<br /> </span>
      </td>
      <td>
        <a [href]="overage.stripeURL" target="_blank">{{ overage.planName || "plan" }}</a>
      </td>
      <td>{{ overage.reportCount }}</td>

      <td>
        <span *ngIf="overage.error">{{ overage.error }}</span>
      </td>
      <td>{{ overage.warningNotificationID }}</td>
      <td>
        <button class="btn btn-danger">Close</button>
        <button class="btn btn-warning">Send Warning</button>
      </td>
    </tr>
  </table>
</div>
