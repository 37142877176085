<style>
  table tr:first-child th {
    border-top: 0;
  }
</style>

<div class="container pt-65px">
  <button data-toggle="modal" data-target="#newOrganizationModal" class="btn float-right btn-primary">New Organization</button>

  <h3>Organizations</h3>

  <div *ngFor="let organization of organizations" class="card my-4 border-0 shadow-sm rounded-0">
    <div class="card-header py-2">
      <table class="table table-sm table-borderless mb-0">
        <tr class="d-flex text-muted">
          <th class="col-2 text-muted font-weight-normal pb-0">
            <small>Organization</small>
          </th>
          <th class="col-2 text-muted font-weight-normal pb-0">
            <small>Plan Name</small>
          </th>
          <th class="col-2 text-muted font-weight-normal pb-0">
            <small>Created</small>
          </th>
          <th class="col-2 text-muted font-weight-normal pb-0">
            <small>Total Reports</small>
          </th>
          <th class="col-4 text-muted font-weight-normal">
            <small>Monthly Quota</small>
          </th>
        </tr>

        <tr class="d-flex">
          <td class="col-2 pt-0">
            <a [routerLink]="['/org', organization.id, 'projects']">{{ organization.name }}</a>
          </td>

          <td class="col-2 pt-0">
            <a class="text-dark" [routerLink]="['/org', organization.id, 'billing']" *ngIf="organization.planName">{{
              organization.planName.split("_")[0]
            }}</a>
          </td>

          <td class="col-2 pt-0">{{ organization.ts | timeAgo }}</td>

          <td class="col-2 pt-0">{{ organization.reportCount }}</td>

          <td class="col-4">
            <app-report-quota-bar [current]="organization.reportCountBillingCycle" [limit]="organization.reportQuotaLimit"> </app-report-quota-bar>
          </td>
        </tr>
      </table>
    </div>
    <div class="card-body py-2" *ngIf="organization.projects?.length">
      <table class="table table-sm mb-0">
        <tr class="d-flex text-muted">
          <th class="col-2 text-muted font-weight-normal">
            <small>Projects</small>
          </th>
          <th class="col-2 text-muted font-weight-normal">
            <small>Last Seen Report</small>
          </th>
          <th class="col-2 text-muted font-weight-normal">
            <small>Open Alerts</small>
          </th>
          <th class="col-2 text-muted font-weight-normal">
            <small>Total Reports</small>
          </th>
          <th class="col-2 text-muted font-weight-normal">
            <small>Monthly Quota</small>
          </th>
        </tr>

        <tr *ngFor="let project of organization.projects" class="d-flex">
          <td class="col-2 border-0 py-2">
            <a [routerLink]="['/p/', project.id, 'analysis']">
              <ng-container *ngIf="project.name.length">{{ project.name }}</ng-container>
              <ng-container *ngIf="!project.name.length">Untitled</ng-container>
            </a>
          </td>

          <td class="col-2 border-0">{{ project.lastReport?.ts | timeAgo }}</td>

          <td class="col-2 border-0">
            <a [routerLink]="['/p', project.id, 'alerts']">{{ project.openAlertEvents }} Alerts</a>
          </td>

          <td class="col-2 border-0">
            <a [routerLink]="['/p', project.id, 'analysis']">{{ project.reportCount }}</a>
          </td>

          <td class="col-4 border-0">
            <app-report-quota-bar [current]="project.reportCountBillingCycle" [limit]="organization.reportQuotaLimit"> </app-report-quota-bar>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<!-- New Organization Modal -->
<div class="modal fade" id="newOrganizationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">New Organization</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label>Organization Name:</label>
          <input class="form-control" [(ngModel)]="newOrganization.name" />
        </div>

        <div class="form-group">
          <label>Plan</label>

          <select class="form-control" [(ngModel)]="newOrganization.planName">
            <option *ngFor="let plan of plans" [ngValue]="plan.name">
              {{ plan.tier }} - ${{ plan.price }}/{{ plan.duration }} -
              {{ plan.reportCount }}
            </option>
          </select>
          <small *ngIf="newOrganization.planName != 'SANDBOX'" class="form-text text-muted"
            ><a routerLink="/pricing">Plan Info</a> The trial period lasts 14 days. Billing will start at the end of 14 days. You can cancel
            anytime.</small
          >
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button class="btn btn-primary" (click)="createNewOrganization()">
          <span *ngIf="newOrganization.planName != 'SANDBOX'">Finalize Billing and Start Trial</span>
          <span *ngIf="newOrganization.planName == 'SANDBOX'">Create Organization</span>
        </button>
      </div>
    </div>
  </div>
</div>
