import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wave-two',
  templateUrl: './wave-two.component.html'
})
export class WaveTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
