import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BillingService } from "src/app/services/billing.service";
import { environment } from "src/environments/environment";
import { Plan } from "src/app/models/plan";

declare var Stripe: any;
declare var elements: any;
declare var $: any;

@Component({
  selector: "app-pricing-panels",
  templateUrl: "./pricing-panels.component.html",
  styleUrls: ["./pricing-panels.component.scss"],
})
export class PricingPanelsComponent implements OnInit {
  @Input() includeTrial: boolean;
  @Output() onPlanSelected = new EventEmitter<string>();

  actionText: string;
  isMonthlyBilling: boolean;
  plans: Plan[];

  constructor(private route: ActivatedRoute, private router: Router, private billingService: BillingService) {}

  ngOnInit() {
    this.billingService.getPlans().subscribe((plans) => {
      this.plans = plans;
    });
    this.isMonthlyBilling = true;
    if (this.includeTrial == undefined || this.includeTrial == null) {
      this.includeTrial = true;
    }

    if (this.includeTrial) {
      this.actionText = "Start 14 Day Trial";
    } else {
      this.actionText = "Select Plan";
    }

    $('[data-toggle="tooltip"]').tooltip();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  checkout(plan: string) {
    this.onPlanSelected.emit(plan);
  }

  getPlanByTier(tier: string): Plan {
    const duration = this.isMonthlyBilling ? "month" : "year";
    const plan = this.plans
      ? this.plans.filter((plan) => plan.tier.toLocaleLowerCase() == tier.toLocaleLowerCase() && plan.duration == duration)[0]
      : ({ price: "", duration: "" } as unknown as Plan);
    return plan;
  }
}
