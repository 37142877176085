<div class="price-card-box">
  <div class="pricing-header mb-4">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label class="btn btn-outline-primary active px-4 py-1 rounded-left" (click)="isMonthlyBilling = true">
        <input type="radio" name="options" id="option1" checked /> Monthly
      </label>
      <label class="btn btn-outline-primary px-4 py-1 rounded-right" (click)="isMonthlyBilling = false">
        <input type="radio" name="options" id="option2" /> Annual </label
      ><span class="discount"> save 10%</span>
    </div>
  </div>
  <div class="cards">
    <!-- <div class="card shadow-sm">
      <ul>
        <li class="plan">Personal</li>
        <li id="basic" class="price bottom-bar">
          ${{ getPlanByTier("personal").price }}<span> / {{ getPlanByTier("personal").duration }}</span>
        </li>
        <li class="bottom-bar">1 website</li>
        <li class="bottom-bar">
          10,000 reports / month
          <i-feather
            class="feather"
            name="help-circle"
            data-toggle="tooltip"
            data-placement="bottom"
            title="'A 'report' is a content-security-policy report that is sent to our endpoints. After installing content-security-policy on your website, the violation reports are sent to csper.io for processing. Each report received counts towards the reports processed / month.'"
          >
          </i-feather>
        </li>
        <li class="bottom-bar">90 day report retention</li>
        <li class="plan-desc">Great for individuals looking to put Content Security Policy on their own website.</li>
        <li>
          <button class="btn btn-outline-primary" (click)="checkout(getPlanByTier('personal').name)">
            {{ actionText }}
          </button>
        </li>
      </ul>
    </div> -->
    <div class="card shadow-sm">
      <ul>
        <li class="plan">Basic</li>
        <li id="basic" class="price bottom-bar">
          ${{ getPlanByTier("basic").price }}
          <span> / {{ getPlanByTier("basic").duration }}</span>
        </li>
        <li class="bottom-bar">3 websites</li>
        <li class="bottom-bar">
          100,000 reports / month<i-feather
            class="feather"
            name="help-circle"
            data-toggle="tooltip"
            data-placement="bottom"
            title="'A 'report' is a content-security-policy report that is sent to our endpoints. After installing content-security-policy on your website, the violation reports are sent to csper.io for processing. Each report received counts towards the reports processed / month.'"
          >
          </i-feather>
        </li>
        <li class="bottom-bar">90 day report retention</li>
        <li class="bottom-bar">Unlimited users</li>
        <li class="plan-desc">Great for teams looking to do an initial rollout of Content-Security-Policy.</li>
        <li>
          <button class="btn btn-outline-primary" (click)="checkout(getPlanByTier('basic').name)">
            {{ actionText }}
          </button>
        </li>
      </ul>
    </div>
    <div class="card active shadow">
      <div class="popular">Most Popular</div>
      <ul>
        <li class="plan">Business</li>
        <li id="professional" class="price bottom-bar">
          ${{ getPlanByTier("business").price }}
          <span> / {{ getPlanByTier("business").duration }}</span>
        </li>
        <li class="bottom-bar">20 websites</li>
        <li class="bottom-bar">
          1,000,000 reports / month<i-feather
            class="feather"
            name="help-circle"
            data-toggle="tooltip"
            data-placement="bottom"
            title="'A 'report' is a content-security-policy report that is sent to our endpoints. After installing content-security-policy on your website, the violation reports are sent to csper.io for processing. Each report received counts towards the reports processed / month.'"
          >
          </i-feather>
        </li>
        <li class="bottom-bar">90 day report retention</li>
        <li class="bottom-bar">Unlimited users</li>
        <li class="plan-desc">Great for businesses looking to monitor Content Security Policy on a suite of websites.</li>
        <li>
          <button class="btn btn-primary" (click)="checkout(getPlanByTier('business').name)">
            {{ actionText }}
          </button>
        </li>
      </ul>
    </div>

    <div class="card shadow-sm">
      <ul>
        <li class="plan">Enterprise</li>
        <li id="professional" class="price bottom-bar">Let's Connect</li>
        <li class="bottom-bar">20+ websites</li>
        <li class="bottom-bar">
          1,000,000+ reports / month<i-feather
            class="feather"
            name="help-circle"
            data-toggle="tooltip"
            data-placement="bottom"
            title="'A 'report' is a content-security-policy report that is sent to our endpoints. After installing content-security-policy on your website, the violation reports are sent to csper.io for processing. Each report received counts towards the reports processed / month.'"
          >
          </i-feather>
        </li>
        <li class="bottom-bar">90+ day report retention</li>
        <li class="bottom-bar">Unlimited users</li>
        <li class="plan-desc">Great for enterprises looking to deploy and monitoring a portfolio of applications.</li>
        <li>
          <button class="btn btn-outline-primary" routerLink="/demo">Schedule Demo</button>
        </li>
      </ul>
    </div>
  </div>
</div>
