<div class="intro" id="jumbo">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 160" class="background-top-img">
    <title>background waves</title>
    <g>
      <g data-name="background shapes">
        <path
          class="cls-1"
          fill="blue"
          opacity="0.3"
          d="M1001.3,183.07c-477.77,0-702.14-122.79-949.95-122.79Q24.46,60.28,0,61V193.12H1024V182.93Q1012.81,183.07,1001.3,183.07Z"
        />
      </g>
    </g>
  </svg>
  <div class="container pl-4">
    <h1 class="pt-5 font-weight-bold"><u>Secure</u> Content Security Policy</h1>

    <p class="lead pb-2">The most advance set of Content Security Policy tools. Built for engineers who care deeply about user security.</p>

    <p class="lead mb-0 py-3">
      <a class="btn btn-secondary btn-lg mr-2 mb-1" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }" role="button"
        >Start Free Trial <i-feather class="feather-chevron-right" name="chevron-right"></i-feather
      ></a>
      <a class="btn btn-outline-primary btn-lg" [routerLink]="['/pricing']" role="button">Pricing</a>
    </p>
  </div>
  <app-wave-one></app-wave-one>

  <div class="container text-center px-0">
    <h2 class="pt-5">Why Use Csper?</h2>
    <p>
      Csper makes deploying and monitoring Content Security Policy a breeze. With automated tools and actionable insights, you'll be protecting your
      users in no time.
    </p>

    <img src="https://assets.csper.io/csper-productfull.webp" class="w-100 p-4" alt="csper report dashboard" />
  </div>
</div>

<div class="pt-2"></div>
<app-wave-two></app-wave-two>
<!-- policy generator -->
<div class="bg-opacity">
  <div class="container pt-5 pb-3">
    <div class="row">
      <div class="col-md-6">
        <h2 class="display-4 m-0">Policy Generator</h2>
        <p class="lead pb-4">Generate a Content Security Policy in minutes with our browser extensions, then view the results on Csper.</p>
        <p class="lead">
          <a
            class="btn btn-info mb-3"
            href="https://chrome.google.com/webstore/detail/content-security-policy-c/ahlnecfloencbkpfnpljbojmjkfgnmdc"
            style="width: 300px"
          >
            <img src="https://assets.csper.io/logo-chrome-colors.svg" class="size-24px" alt="chrome extension" />
            View on Chrome Extension Store</a
          >

          <a class="btn btn-info mb-2" href="https://addons.mozilla.org/en-US/firefox/addon/csp-generator/" style="width: 300px">
            <img src="https://assets.csper.io/logo-firefox-color.svg" class="size-24px" alt="firefox extension" />
            View on Firefox Addon Store</a
          >
        </p>
      </div>
      <div class="col-md-6">
        <div class="text-center">
          <img src="https://assets.csper.io/csper_demo_short_1.webp" class="img-fluid rounded shadow" alt="Content Security Policy (CSP) generator" />
          <p>
            <small>Content Security Policy (CSP) Generator Browser Extension.</small>
          </p>
        </div>
      </div>
    </div>
  </div>
  <app-wave-bottom></app-wave-bottom>
</div>

<!-- features -->
<div class="container text-center">
  <h2 class="pt-5">Features</h2>
  <p class="pb-3">Advance features to help you deploy and monitor CSP in minutes.</p>
  <div class="row m-0 p-3">
    <div class="col-md-4" *ngFor="let feature of features">
      <div class="p-2 mb-0 text-dark rounded-top font-weight-bold">
        {{ feature.title }}
      </div>
      <div class="pb-2">
        <img src="{{ feature.img }}" class="img-fluid img-thumbnail rounded-0 w-100 mb-2 border-0 shadow" alt="{{ feature.alt }}" />
        <p class="text-gray pb-2">{{ feature.description }}</p>
      </div>
    </div>
  </div>
</div>

<!-- scan for csp -->
<div class="bg-opacity">
  <app-wave-top></app-wave-top>
  <div class="container">
    <div class="row">
      <div class="col-md-9">
        <div class="text-center text-dark py-5">
          <div class="p-2 p-md-4">
            <h3>Scan a website for CSP</h3>
            <p>Csper will check if the website has Content Security Policy enabled.</p>
          </div>

          <form (submit)="scan()">
            <div class="form-group">
              <div *ngIf="errorStr" class="text-danger">{{ errorStr }}</div>
              <input class="form-control form-control-success" [(ngModel)]="evaluation.URL" name="url" placeholder="https://website.com" />
              <button type="submit" class="btn btn-primary">Scan website</button>
            </div>

            <div class="clearfix"></div>

            <div *ngIf="errorStr" class="text-danger">
              <div class="card bg-opacity border-0" *ngIf="errorStr != 'missing http or https'">
                <div class="card-body">
                  <button
                    class="btn btn-secondary btn-lg float-md-right"
                    [routerLink]="['/register']"
                    [queryParams]="{ redirect: '/new' }"
                    role="button"
                  >
                    Get Started Building a Policy
                  </button>

                  <p class="card-text text-dark">There doesn't seem to be a policy there.</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-3 d-flex flex-wrap align-items-center">
        <img src="https://assets.csper.io/scan-your-site.webp" alt="scan website for csp" class="d-flex flex-wrap align-items-center w-100" />
      </div>
    </div>
    <div class="p-4"></div>
  </div>
  <app-wave-bottom-decorated-one></app-wave-bottom-decorated-one>
</div>

<!-- what is csp -->
<div class="container py-4">
  <div class="text-center what-is-csp">
    <h3 class="pb-2">What is Content Security Policy?</h3>
    <p>
      Content Security Policy (CSP) is a security feature built into all the major web browsers that helps website owners keep their users safe from
      attacks such as Cross Site Scripting.
    </p>

    <p>
      Historical it's been difficult to setup and use Content-Security-Policy correctly<sup
        ><a href="https://research.google/pubs/pub45542/" rel="noopener" target="_blank">[1]</a></sup
      >. But now with Csper it's easier than ever.
    </p>
  </div>
</div>

<!-- articles -->
<div class="container">
  <div class="text-center">
    <h2 class="pt-5">Top Articles</h2>
    <p class="pb-3">Not sure what Content Security Policy is? Read the articles on best practices</p>
  </div>

  <div class="card-deck">
    <div class="card shadow border-0" [hover-class]="'shadow-lg'">
      <a [routerLink]="['/blog/an-introduction-to-content-security-policy']">
        <div class="card-body content-body-link">
          <img src="https://assets.csper.io/intro-csp-blog-1.webp" alt="CSP nodejs and express" width="100%" />
          <h6 class="card-title pt-2">An Introduction To Content Security Policy</h6>
          <p class="card-text text-muted pt-2 text">
            <small class="text-muted"
              >This article is a very introductory background to Content Security Policy, it is intended for individuals who have some technical
              experience, but have never dug into content security policy.</small
            >
          </p>
        </div>
      </a>
    </div>
    <div class="card shadow border-0" [hover-class]="'shadow-lg'">
      <a [routerLink]="['/blog/csp-nodejs-express-csper']">
        <div class="card-body">
          <img src="https://assets.csper.io/express-nodejs-how-toget-started-height.webp" alt="CSP nodejs and express" width="100%" />
          <h6 class="card-title pt-2 content-body-link">Getting Started with Content Security Policy using NodeJS/Express and Csper</h6>
          <p class="card-text text-muted pt-2">
            <small class="text-muted"
              >This guide covers building and installing content security policy (CSP) on a nodejs express server. This guide covers content security
              policy, generating a content security policy, report-uri, inline scripts, and deployment.</small
            >
          </p>
        </div>
      </a>
    </div>
    <div class="card shadow border-0" [hover-class]="'shadow-lg'">
      <a [routerLink]="['/blog/other-csp-security']">
        <div class="card-body content-body-link">
          <img src="https://assets.csper.io/security-features-csp-1.webp" alt="CSP nodejs and express" width="100%" />
          <h6 class="card-title pt-2">Other Security Features of Content Security Policy</h6>
          <p class="card-text text-muted pt-2">
            <small class="text-muted"
              >Other security features of content security policy including upgrade-insecure-requests, block-all-mixed-content, frame-ancestors,
              sandbox, form-actions, and more.</small
            >
          </p>
        </div>
      </a>
    </div>
  </div>
</div>

<!-- why csper -->
<app-wave-top></app-wave-top>
<div class="bg-opacity mb-5">
  <div class="container py-5">
    <div class="text-center">
      <h3 class="pb-2">Find out why over 30,000 websites are using Csper</h3>
      <p class="lead mb-0 pt-3">
        <a class="btn btn-secondary btn-lg mr-2 mb-1" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }" role="button"
          >Start Free Trial</a
        >
        <a class="btn btn-outline-primary btn-lg" [routerLink]="['/pricing']" [queryParams]="{ redirect: '/new' }" role="button">View pricing</a>
      </p>
    </div>
  </div>
  <app-wave-three></app-wave-three>
</div>
<app-footer></app-footer>
