import { Component, OnInit } from "@angular/core";
import { ProjectService } from "src/app/services/project.service";
import { Project } from "src/app/models/project";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { OrganizationService } from "src/app/services/organization.service";
import { Organization } from "src/app/models/organization";
import { BillingService } from "src/app/services/billing.service";
import { Plan } from "src/app/models/plan";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";

declare var Stripe: any;
declare var $: any;

@Component({
  selector: "app-organizations",
  templateUrl: "./organizations.component.html",
  styleUrls: ["./organizations.component.css"],
})
export class OrganizationsComponent implements OnInit {
  organizations: Organization[];
  newOrganization: Organization;
  plans: Plan[];

  error: string;

  constructor(
    private organizationService: OrganizationService,
    private router: Router,
    private userService: UserService,
    private billingService: BillingService,
    private titleService: Title
  ) {
    this.newOrganization = {} as Organization;
  }

  ngOnInit() {
    this.titleService.setTitle("Organizations");
    // Insert Stripe
    var tag = document.createElement("script");
    tag.src = "https://js.stripe.com/v3/";
    document.getElementsByTagName("head")[0].appendChild(tag);

    this.userService.isLoggedIn$.subscribe(
      (loggedIn) => {
        if (loggedIn) {
          this.organizationService.getOrganizationsFull().subscribe((orgs) => {
            orgs.sort((a, b) => parseInt(a.id, 16) - parseInt(b.id, 16));
            this.organizations = orgs;

            if (this.organizations.length == 0) {
              this.router.navigate(["/new"]);
              return;
            }

            // this.userService.user$.subscribe((me) => {
            //   for (let org of this.organizations) {
            //     for (let member of org.members) {
            //       if (member.email == member.email && (member.role == "WRITE" || member.role == "ADMIN")) {
            //         this.writeableOrganizations.push(org)
            //       }
            //     }
            //   }
            // })

            this.billingService.getPlans().subscribe((p) => {
              this.plans = p;
              if (!this.newOrganization.planName) {
                this.newOrganization.planName = p[0].name;
              }
            });
          });
        } else {
          this.userService.redirectRegister();
        }
      },
      () => {
        this.userService.redirectRegister();
      }
    );
  }

  createNewOrganization() {
    this.organizationService
      .newOrganization({
        name: this.newOrganization.name,
        planName: this.newOrganization.planName,
      } as Organization)
      .subscribe((org) => {
        $("#newOrganizationModal").modal("hide");

        if (org.planName == "SANDBOX") {
          this.router.navigate(["/org", org.id, "projects"]);
          return;
        }

        const stripe = Stripe(environment.stripeKey);

        stripe
          .redirectToCheckout({
            sessionId: org.checkoutSessionID,
          })
          .then(function (result) {
            console.log(result);
          });
      });
  }
}
