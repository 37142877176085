<div class="pt-65px"></div>

<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <h2>Register</h2>

      <div class="card">
        <div class="card-body">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="isAgreeLegalSocial" id="inactive" [ngModelOptions]="{ standalone: true }" />
            <label class="form-check-label" for="inactive">
              I agree to the
              <a target="_blank" routerLink="/tos">Terms of Service</a> and <a target="_blank" routerLink="/privacy">Privacy Policy</a>.
            </label>
          </div>
          <br />

          <a href="/login/google" class="btn btn-primary" [class.disabled]="!isAgreeLegalSocial"
            ><img alt="google logo" src="https://assets.csper.io/google.svg" /> Google</a
          >
          <a href="/login/github" class="btn btn-primary ml-1" [class.disabled]="!isAgreeLegalSocial"
            ><img alt=" github logo" src="https://assets.csper.io/github.svg" class="size-18px" /> Github</a
          >
        </div>
      </div>
      <p class="text-center my-3">or</p>

      <div class="card">
        <div class="card-body">
          <form #registerForm="ngForm" (submit)="register()">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="firstName">First Name</label>
                  <input
                    [(ngModel)]="user.firstName"
                    name="firstName"
                    class="form-control"
                    #firstName="ngModel"
                    required
                    [class.is-valid]="firstName.valid"
                    [class.is-invalid]="firstName.invalid && firstName.touched"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    [(ngModel)]="user.lastName"
                    name="lastName"
                    class="form-control"
                    #lastName="ngModel"
                    required
                    [class.is-valid]="lastName.valid"
                    [class.is-invalid]="lastName.invalid && lastName.touched"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Email</label>
              <input
                type="email"
                [(ngModel)]="user.email"
                name="email"
                class="form-control"
                #email="ngModel"
                required
                email
                [class.is-valid]="email.valid"
                [class.is-invalid]="email.invalid && email.touched"
              />
            </div>
            <div class="form-group">
              <label>Password</label>
              <input
                type="password"
                [(ngModel)]="user.password"
                name="password"
                class="form-control"
                #password="ngModel"
                minlength="8"
                required
                [class.is-valid]="password.valid"
                [class.is-invalid]="password.invalid && password.touched"
              />
              <small id="passwordHelpBlock" class="form-text text-muted"> Your password must be at least 8 characters long. </small>
            </div>
            <div *ngIf="errorString.length != 0" class="text-danger">Invalid Login: {{ errorString }}</div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="isAgreeLegal" id="inactive" [ngModelOptions]="{ standalone: true }" />
              <label class="form-check-label" for="inactive">
                I agree to the
                <a target="_blank" routerLink="/tos">Terms of Service</a> and <a target="_blank" routerLink="/privacy">Privacy Policy</a>.
              </label>
            </div>
            <br />

            <button type="submit" [disabled]="!registerForm.form.valid || !isAgreeLegal" class="btn btn-primary">Register</button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>
</div>
