import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wave-three',
  templateUrl: './wave-three.component.html'
})
export class WaveThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
