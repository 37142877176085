<div class="container pt-65px">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <h2>Forgot Password</h2>

      <a href="/login/google" class="btn btn-primary"><img alt="google logo" src="https://assets.csper.io/google.svg" /> Google</a>
      <a href="/login/github" class="btn btn-primary ml-1"
        ><img alt="github logo" src="https://assets.csper.io/github.svg" class="size-18px" /> Github</a
      >

      <hr />
      <form #loginForm="ngForm" (submit)="forgotPassword()">
        <div class="form-group">
          <label>Email</label>
          <input
            type="email"
            [(ngModel)]="user.email"
            class="form-control"
            name="email"
            #email="ngModel"
            required
            email
            [class.is-valid]="email.valid"
            [class.is-invalid]="email.invalid && email.touched"
          />
        </div>
        <div *ngIf="successString" class="text-success">
          {{ successString }}
        </div>
        <div *ngIf="errorString" class="text-danger">Invalid Login: {{ errorString }}</div>
        <a routerLink="/register" class="float-right" tabindex="-1">Register</a>
        <button type="submit" [disabled]="!loginForm.form.valid" class="btn btn-primary">Reset password</button>
      </form>
    </div>
    <div class="col-md-3"></div>
  </div>
</div>
