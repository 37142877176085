<div class="container pt-65px">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <h2>Reset Password</h2>

      <form #loginForm="ngForm" (submit)="resetPassword()">
        <div class="form-group">
          <label>Password</label>
          <input
            type="password"
            [(ngModel)]="user.email"
            class="form-control"
            name="email"
            #email="ngModel"
            required
            minlength="8"
            [class.is-valid]="email.valid"
            [class.is-invalid]="email.invalid && email.touched"
          />
        </div>

        <div class="form-group">
          <label>Password Confirm</label>
          <input
            type="password"
            [(ngModel)]="user.password"
            class="form-control"
            name="password"
            #password="ngModel"
            required
            minlength="8"
            [class.is-valid]="password.valid"
            [class.is-invalid]="password.invalid && password.touched"
          />
        </div>
        <div *ngIf="errorString" class="text-danger">{{ errorString }}</div>
        <div *ngIf="successString" class="text-success">
          {{ successString }}
          <a routerLink="/login">Login</a>
        </div>

        <button type="submit" [disabled]="!loginForm.form.valid" class="btn btn-primary">Set Password</button>
      </form>
    </div>
    <div class="col-md-3"></div>
  </div>
</div>
