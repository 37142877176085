<div class="pt-65px" *ngIf="organizations">
  <table class="table table-condensed table-bordered">
    <tr>
      <th>Plan</th>
      <th>Name</th>
      <th>id</th>
      <th>ts</th>
      <th>Active</th>
      <th>Report Count</th>
      <th>quota/last seen</th>
      <th>members</th>
    </tr>
    <ng-container *ngFor="let organization of organizations">
      <tr>
        <td>{{ organization.planName }}</td>
        <td>{{ organization.name }}</td>
        <td>{{ organization.id }}</td>
        <td>{{ organization.ts | timeAgo }}</td>
        <td [class.text-success]="!organization.isDisabled">
          {{ !organization.isDisabled }}
        </td>
        <td>{{ organization.reportCount }}</td>
        <td>{{ organization.reportCountBillingCycle }}</td>
        <td>
          <div *ngFor="let member of organization.members">{{ member.email }} {{ member.role }}</div>
        </td>
        <td>
          <a class="btn btn-danger" (click)="purgeOrganization(organization.id)">Purge Organization</a>
        </td>
      </tr>

      <tr *ngFor="let project of organization.projects">
        <td></td>
        <td>{{ project.name }}</td>
        <td>{{ project.id }}</td>
        <td>{{ project.ts | timeAgo }}</td>
        <td [class.text-success]="!project.isDisabled">
          {{ !project.isDisabled }}
        </td>
        <td>{{ project.reportCount }}</td>
        <td>{{ project.lastReport?.documentURIOrigin || "" }}</td>
        <td>
          <div *ngFor="let member of project.members">{{ member.email }} {{ member.role }}</div>
        </td>

        <td>
          <a class="btn btn-danger" (click)="purgeProject(project.id)">Purge Project</a>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
