<div class="pt-65px">
  <div class="row">
    <div class="col-md-2">
      <ul>
        <li *ngFor="let page of pages" (click)="selectedPage = page">
          {{ page.slug }}
        </li>
      </ul>
      <button class="btn btn-secondary" (click)="newPage()">New</button>
    </div>

    <div class="col-md-10" *ngIf="selectedPage">
      <div class="clearfix">
        <button class="btn btn-primary float-right" (click)="updatePage()">Save</button>
        <h2>{{ selectedPage.title }}</h2>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Title</label>
            <input [(ngModel)]="selectedPage.title" placeholder="title" class="form-control" />
          </div>
          <div class="form-group">
            <label>slug</label>
            <input [(ngModel)]="selectedPage.slug" placeholder="slug" class="form-control" />
          </div>
          <div class="form-group">
            <label>Cover Image</label>
            <input [(ngModel)]="selectedPage.coverImage" placeholder="coverImage" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Meta Description</label>
            <textarea [(ngModel)]="selectedPage.description" placeholder="description" class="form-control"></textarea>
          </div>
          <div class="form-group">
            <label>Category</label>

            <input [(ngModel)]="selectedPage.category" placeholder="video, article, guide" class="form-control" />
          </div>
          <div class="form-group">
            <div class="form-check"><input type="checkbox" [(ngModel)]="selectedPage.isPublic" class="form-check-input" />Is Public</div>
            <div class="form-check"><input type="checkbox" [(ngModel)]="selectedPage.isBlog" class="form-check-input" />Is Blog Post</div>
          </div>
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="errorStr">{{ errorStr }}</div>

      <h3>Content</h3>

      <textarea [(ngModel)]="selectedPage.raw" rows="20" class="form-control"></textarea>

      <pre>{{ selectedPage | json }}</pre>
    </div>
  </div>
</div>
