<div class="container-fluid pt-65px">
  <div class="form-group">
    <label>Search</label>
    <input [(ngModel)]="searchStr" class="form-control" />
  </div>
  <div class="form-group">
    <label>Page</label>
    <input [(ngModel)]="page" class="form-control" />
  </div>
  <button (click)="search()" class="btn btn-primary">Search</button>

  <table class="table table-condensed">
    <tr>
      <th>Email</th>
      <th>Name</th>
      <th>Auth</th>
      <th>Register</th>
      <th>Projects</th>
      <th>Actions</th>
    </tr>

    <tr *ngFor="let profile of profiles">
      <td>
        <a [routerLink]="['/admin/profiles', profile.id]">{{ profile.email | slice: 0:30 }}</a>
      </td>
      <td>
        {{ profile.user.firstName | slice: 0:15 }}
        {{ profile.user.lastName | slice: 0:15 }}<br />
        {{ profile.user.id }}
      </td>
      <td>{{ profile.user.authMechanism }}</td>
      <td>
        <span *ngIf="profile.user.ts">{{ profile.user.ts | timeAgo }}</span>
      </td>
      <td>
        <p>
          Organizations<br />
          <span *ngFor="let organization of profile.organizations">
            {{ organization.id }} {{ organization.planName }} <strong>{{ organization.name | slice: 0:20 }}</strong
            ><br />

            <span *ngFor="let project of organization.projects">
              <span *ngIf="project.organizationID == organization.id">
                &nbsp;&nbsp;&nbsp;&nbsp;<i>{{ project.id }} {{ project.name | slice: 0:20 }}</i
                ><br />
              </span>
            </span>
          </span>
        </p>

        <p>
          Projects<br />
          <span *ngFor="let project of profile.projects">
            {{ project.id }} <strong>{{ project.name | slice: 0:20 }}</strong
            ><br />
          </span>
        </p>
      </td>
    </tr>
  </table>
</div>

<!-- <pre>{{profiles | json}}</pre> -->
