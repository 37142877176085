import { Component, OnInit } from "@angular/core";
import { PageService } from "src/app/services/page.service";
import { Page } from "src/app/models/cms";

@Component({
  selector: "app-cms",
  templateUrl: "./cms.component.html",
  styleUrls: ["./cms.component.css"],
})
export class CmsComponent implements OnInit {
  pages: Page[];
  selectedPage: Page;

  errorStr: string;

  constructor(private pageService: PageService) {}

  ngOnInit() {
    this.pageService.getPages().subscribe((pages) => {
      this.pages = pages;
      this.selectedPage = pages[0];
    });
  }

  updatePage() {
    this.errorStr = "";
    this.pageService.updatePage(this.selectedPage).subscribe(
      (page) => {
        this.selectedPage = page;
      },
      (error) => {
        this.errorStr = error;
      }
    );
  }

  newPage() {
    this.pageService.savePage({} as Page).subscribe((page) => {
      this.selectedPage = page;
    });
  }
}
