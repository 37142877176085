import { Pipe, PipeTransform } from "@angular/core";
import { Organization } from "../models/organization";

@Pipe({
  name: "projectOrgSearch",
})
export class ProjectOrgSearchPipe implements PipeTransform {
  transform(organizations: Organization[], search: string): Organization[] {
    if (!organizations) {
      return [];
    }

    if (!search || search == "") {
      organizations.sort((a, b) => parseInt(a.id, 16) - parseInt(b.id, 16));
      return organizations;
    }

    let out = [];
    for (let organization of organizations) {
      if (organization.name.indexOf(search) > -1) {
        out.push(organization);
        continue;
      }

      for (let project of organization.projects) {
        if (project.name.indexOf(search) > -1) {
          out.push(organization);
          break;
        }
      }
    }

    out.sort((a, b) => parseInt(b.id, 16) - parseInt(a.id, 16));

    return out;
  }
}
