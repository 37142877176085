import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "./config.service";
import { Plan } from "../models/plan";
import { Invoice } from "../models/invoice";
import { Organization } from "../models/organization";

interface CheckoutResponse {
  sessionID: string;
}

@Injectable({
  providedIn: "root",
})
export class BillingService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  getPlans(): Observable<Plan[]> {
    return this.http.get<Plan[]>(`${this.config.getOrigin()}/api/billing/plans`);
  }

  startCheckout(organizationID: string, reportCount: string): Observable<CheckoutResponse> {
    return this.http.get<CheckoutResponse>(
      `${this.config.getOrigin()}/api/organizations/${organizationID}/billing/checkout?reportCount=${reportCount}`
    );
  }

  getNextInvoice(organizationID: string): Observable<Invoice> {
    return this.http.get<Invoice>(`${this.config.getOrigin()}/api/organizations/${organizationID}/billing/invoices/next`);
  }

  getInvoices(organizationID: string): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${this.config.getOrigin()}/api/organizations/${organizationID}/billing/invoices`);
  }

  changePlan(organizationID: string, plan: Plan): Observable<Organization> {
    return this.http.put<Organization>(`${this.config.getOrigin()}/api/organizations/${organizationID}/billing`, plan);
  }

  getPlanChangeCost(organizationID: string): Observable<Plan[]> {
    return this.http.get<Plan[]>(`${this.config.getOrigin()}/api/organizations/${organizationID}/billing/upgrades`);
  }

  getBillingPortalURL(organizationID: string): string {
    return `${this.config.getOrigin()}/api/organizations/${organizationID}/billing/portal`;
  }
}
