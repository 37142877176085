<div class="container pt-65px">
  <h2>Contact</h2>

  <p>Support Email: <strong>support@csper.io</strong></p>

  <p>We'd love to hear from you! Feel free to send questions, comments, feedback.</p>
  <!-- 
  <div *ngIf="!commentSent">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="firstName"><strong>First Name</strong></label>
          <input type="text" [(ngModel)]="comment.first" class="form-control" id="firstName" aria-describedby="firstName" placeholder="First Name" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="lastName"><strong>Last Name</strong></label>
          <input type="text" [(ngModel)]="comment.last" class="form-control" id="lastName" aria-describedby="lastName" placeholder="Last Name" />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="exampleInputEmail1"><strong>Reply Email address</strong></label>
      <input
        type="email"
        [(ngModel)]="comment.email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Enter email"
      />
    </div>

    <div class="form-group">
      <label for="exampleInputEmail1"><strong>Comment / Feedback / Question</strong></label>
      <textarea
        type="comments"
        [(ngModel)]="comment.feedback"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Comment / Feedback / Question"
      ></textarea>
    </div>
    <button class="btn btn-primary float-right" (click)="saveComment()">Submit</button>
  </div>

  <div class="alert alert-success" *ngIf="commentSent">
    <p><strong>Team Notified!</strong> Except an email shortly.</p>
  </div> -->
</div>
