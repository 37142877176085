import { Component, OnInit } from "@angular/core";
import { ProjectService } from "src/app/services/project.service";
import { Feedback } from "src/app/models/feedback";

@Component({
  selector: "app-schedule-demo",
  templateUrl: "./schedule-demo.component.html",
  styleUrls: ["./schedule-demo.component.css"],
})
export class ScheduleDemoComponent implements OnInit {
  feedback: Feedback;
  feedbackSent: boolean;

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.feedback = {} as Feedback;
  }

  saveFeedback() {
    this.projectService.sendFeedback(this.feedback).subscribe(() => {
      this.feedbackSent = true;
    });
  }
}
