<div class="pt-65px"></div>

<div class="container" *ngIf="mainBlog">
  <h4 class="pb-3 text-center">Csper Blog</h4>
  <div class="row py-4">
    <div class="col-sm-7">
      <div class="card border-0 shadow-sm bg-opacity mb-3" [routerLink]="['/blog', mainBlog.slug]" [hover-class]="'shadow-lg'">
        <div class="card-body">
          <img src="{{ mainBlog.coverImage }}" alt="{{ mainBlog.title }}" width="595" height="280" style="width: 100%; height: auto;">
          <h5 class="pt-2">
            <a class="text-primary" [routerLink]="['/blog', mainBlog.slug]">{{ mainBlog.title }}</a>
          </h5>
          <p>{{ mainBlog.description }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="card-body p-0 mb-2 font-weight-bold">Top Articles:</div>
      <div class="card border-0 mb-2" *ngFor="let blog of selectedBlogs" [hover-class]="'shadow'">
        <div class="card-body bg-light">
          <div>
            <a class="text-primary" [routerLink]="['/blog', blog.slug]">{{ blog.title }}</a>
          </div>
          <div>{{ blog.description }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pt-3" *ngIf="posts">
  <app-wave-top-decorated-flipped></app-wave-top-decorated-flipped>
  <app-email-subscribe [isCondenced]="true"></app-email-subscribe>
</div>

<!-- TODO: Add tag filters -->
<!-- 
<div class="container pt-2 text-center">
  <span class="badge badge-pill badge-success mx-1" *ngFor="let category of blogPostCategories">{{category}}</span>
</div> -->

<div class="container pt-4">
  <div class="row">
    <div class="col-md-4" *ngFor="let post of posts">
      <div
        class="card mb-3 shadow border-0"
        [class.shadow-lg]="post.hover"
        (mouseover)="post.hover = true"
        (mouseout)="post.hover = false"
        [routerLink]="['/blog', post.slug]"
        style="min-height: 460px"
      >
        <div class="d-flex justify-content-center align-items-center blog-post-img-container border-0 bg-light">
          <img class="card-img-top p-1 bg-light blog-post-img" src="{{ post.coverImage }}" alt="Card image cap" width="350" height="190"/>
        </div>

        <div class="card-body pt-1 bg-light">
          <p class="card-text mb-0">
            <small class="text-success">{{ post.category }}</small>
          </p>
          <h5 class="card-title mb-0">
            <a class="text-primary" [routerLink]="['/blog', post.slug]">{{ post.title }}</a>
          </h5>
          <!-- <p class="card-text mb-2">
        <small class="text-muted">Stuart Larsen</small>
      </p> -->
          <p class="card-text">
            <small class="text-muted">{{ shortDescription(post.description) }}</small>
          </p>
        </div>
        <p class="card-text text-right mb-0 p-2 bg-light">
          <small class="text-muted">{{ post.ts | timeAgo }}</small>
        </p>
      </div>
    </div>
  </div>
</div>
<app-wave-top *ngIf="posts"></app-wave-top>
<div class="bg-opacity mb-5" *ngIf="posts">
  <div class="container pt-5">
    <div class="text-center m-auto" style="max-width: 800px">
      <h5 class="pb-2">
        Csper makes deploying and monitoring Content Security Policy a breeze. With automated tools and actionable insights, you'll be protecting your
        users in no time.
      </h5>
      <p class="lead mb-0 pt-3">
        <a class="btn btn-secondary btn-lg mr-2 mb-1" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }" role="button"
          >Start Free Trial</a
        >
        <a class="btn btn-outline-primary btn-lg" [routerLink]="['/pricing']" [queryParams]="{ redirect: '/new' }" role="button">View pricing</a>
      </p>
    </div>
  </div>
  <app-wave-three></app-wave-three>
</div>
<a href="http://www.freepik.com" style="display: none">Designed by slidesgo / Freepik</a>

<app-footer *ngIf="posts"></app-footer>
