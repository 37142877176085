import { Component, OnInit } from "@angular/core";
import { AdminService } from "src/app/services/admin.service";
import { Profile } from "src/app/models/user";

@Component({
  selector: "app-admin-profiles",
  templateUrl: "./admin-profiles.component.html",
  styleUrls: ["./admin-profiles.component.css"],
})
export class AdminProfilesComponent implements OnInit {
  profiles: Profile[];
  selectedProfile: Profile;

  page: number;
  searchStr: string;

  constructor(private adminService: AdminService) {
    this.page = 0;
    this.searchStr = "";
  }

  ngOnInit() {
    this.search();
  }

  search() {
    this.adminService.getProfiles(this.searchStr, this.page).subscribe((profiles) => {
      this.profiles = profiles;
    });
  }

  selectProfile(email: string) {
    this.adminService.getProfile(email).subscribe((p) => {
      this.selectedProfile = p;
    });
  }
}
