import { Pipe, PipeTransform } from "@angular/core";
import { Report, ReportGroup } from "../models/report";

@Pipe({
  name: "search",
})
export class SearchPipe implements PipeTransform {
  transform(reports: ReportGroup[], keys: string[], keyword: string): ReportGroup[] {
    if (!reports || !keys || !keyword || keyword == "") {
      return reports;
    } // no array

    var out: ReportGroup[] = [];

    for (let report of reports) {
      for (var key of keys) {
        if (report[key].includes(keyword)) {
          out.push(report);
        }
      }
    }

    return out;
  }
}
