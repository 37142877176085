<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 130">
    <title>background waves</title>
    <g>
      <g id="background_shapes" data-name="background shapes">
        <path
          class="cls-1"
          fill="blue"
          opacity="0.3"
          d="M898.6,0C634.05,0,413,132.84,42.42,132.84c-15.07,0-29.18-.39-42.42-1.12v61.4H1024v-183A775.82,775.82,0,0,0,898.6,0Z"
        />
        <path
          class="cls-1"
          fill="blue"
          opacity="0.3"
          d="M1001.3,183.07c-477.77,0-702.14-122.79-949.95-122.79Q24.46,60.28,0,61V193.12H1024V182.93Q1012.81,183.07,1001.3,183.07Z"
        />
      </g>
    </g>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-4, 30)" fill="white" fill-rule="nonzero">
        <path
          d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,14.035 Z"
        ></path>
      </g>
    </g>
  </svg>