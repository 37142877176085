import { Component, OnInit } from "@angular/core";
import { RecommendationService } from "src/app/services/recommendation.service";
import { ActivatedRoute } from "@angular/router";
import { Evaluation } from "src/app/models/recommendations";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-view-evaluation",
  templateUrl: "./view-evaluation.component.html",
  styleUrls: ["./view-evaluation.component.css"],
})
export class ViewEvaluationComponent implements OnInit {
  evaluation: Evaluation;
  evaluationID: string;

  constructor(
    private recommendationService: RecommendationService,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.evaluation = {} as Evaluation;
  }

  ngOnInit() {
    this.titleService.setTitle("Evaluation");
    this.metaService.updateTag({
      name: "description",
      content: "Evaluate your CSP policies. Check for common mis-configurations in your content-security-policy directives.",
    });

    this.evaluationID = this.route.snapshot.paramMap.get("evaluationID");
    this.recommendationService.getEvaluation(this.evaluationID).subscribe((e) => {
      this.evaluation = e;
      this.titleService.setTitle("Evaluation " + e.URL);
      this.metaService.updateTag({
        name: "description",
        content: "Evaluation for " + e.URL + ". Check for common mis-configurations in your content-security-policy directives.",
      });
    });
  }

  convertDocLink(link: string): string {
    var conversion: any = {
      "https://docs.csper.io/learning/learning-csp/sources": "https://csper.io/docs/sources",
      "https://docs.csper.io/learning/learning-csp/directives": "https://csper.io/docs/directives",
      "https://docs.csper.io/learning/learning-csp/report-uri": "https://csper.io/docs/report-uri",
    };

    if (conversion[link] != undefined) {
      return conversion[link];
    }

    return link;
  }
}
