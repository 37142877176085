import { Component, OnInit } from "@angular/core";
import { PageService } from "src/app/services/page.service";
import { TableOfContents, Page } from "src/app/models/cms";
import { ActivatedRoute } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-docs",
  templateUrl: "./docs.component.html",
  styleUrls: ["./docs.component.css"],
})
export class DocsComponent implements OnInit {
  slug: string;

  tocs: TableOfContents[];
  page: Page;

  errorStr: string;

  constructor(private pageService: PageService, private route: ActivatedRoute, private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.slug = this.route.snapshot.paramMap.get("slug");

    this.route.paramMap.subscribe((params) => {
      this.slug = params.get("slug");
      this.pageService.getPage(this.slug).subscribe(
        (page) => {
          this.loadPage(page);
          this.errorStr = "";
        },
        (error) => {
          this.errorStr = error;
          this.page = null;
        }
      );
    });

    this.pageService.getDocsTOC().subscribe((tocs) => {
      this.tocs = tocs;
    });

    this.pageService.getPage(this.slug).subscribe(
      (page) => {
        this.loadPage(page);
        this.errorStr = "";
      },
      (error) => {
        this.errorStr = error;
      }
    );
  }

  loadPage(page: Page) {
    this.titleService.setTitle(page.title);
    this.metaService.updateTag({
      name: "description",
      content: page.description,
    });
    this.page = page;
  }
}
