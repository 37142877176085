import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wave-top-decorated-flipped',
  templateUrl: './wave-top-decorated-flipped.component.html'
})
export class WaveTopDecoratedFlippedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
