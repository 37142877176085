import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { Observable } from "rxjs";
import { Page, TableOfContents } from "../models/cms";

@Injectable({
  providedIn: "root",
})
export class PageService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  getBlogPosts(): Observable<Page[]> {
    return this.http.get<Page[]>(`${this.config.getOrigin()}/api/cms/pages/blog`);
  }

  getPages(): Observable<Page[]> {
    return this.http.get<Page[]>(`${this.config.getOrigin()}/api/cms/pages`);
  }

  updatePage(page: Page): Observable<Page> {
    return this.http.put<Page>(`${this.config.getOrigin()}/api/cms/pages`, page);
  }

  savePage(page: Page): Observable<Page> {
    return this.http.post<Page>(`${this.config.getOrigin()}/api/cms/pages`, page);
  }

  getDocsTOC(): Observable<TableOfContents[]> {
    return this.http.get<TableOfContents[]>(`${this.config.getOrigin()}/api/cms/docs/toc`);
  }

  getPage(slug: string): Observable<Page> {
    return this.http.get<Page>(`${this.config.getOrigin()}/api/cms/docs/${slug}`);
  }

  // newPage(page: Page)
}
