<div class="container pt-65px">
  <h1 class="text-center">Protected Badges</h1>
  <p class="text-center">
    Let your customers know that you take their security seriously.
  </p>

  <h2 style="display: inline;">Light badge</h2><span style="vertical-align: top; background: #3d55cc; color: white;border-radius: 4px; padding: 1px 4px;font-size: 12px;">Most popular</span>
  <div class="bg-opacity p-2 mb-4 mt-2">
    <pre><code class="language-json line-numbers"><textarea readonly> <a title="Csper" href="https://csper.io/" target="_blank" rel="noopener noreferrer">
    <img src="https://assets.csper.io/badges/csper_white.webp" width="130" height="44"  alt="Csper"/>
 </a></textarea>
</code></pre>
    <a title="Csper" href="https://csper.io/" target="_blank" rel="noopener noreferrer">
      <img src="https://assets.csper.io/badges/csper_white.webp" width="130" height="44" alt="Csper" class="my-2" />
    </a>
  </div>

  <h2>Dark badge</h2>
  <div class="bg-opacity p-2 mb-4 mt-2">
    <pre><code class="language-json line-numbers"><textarea readonly> <a title="Csper" href="https://csper.io/" target="_blank" rel="noopener noreferrer">
    <img src="https://assets.csper.io/badges/csper_dark_white.webp" width="130" height="44"  alt="Csper"/>
 </a></textarea>
</code></pre>
    <a title="Csper" href="https://csper.io/" target="_blank" rel="noopener noreferrer">
      <img src="https://assets.csper.io/badges/csper_dark_white.webp" width="130" height="44" alt="Csper" class="my-2" />
    </a>
  </div>

  <h2>Blue badge</h2>
  <div class="bg-opacity p-2 mb-4 mt-2">
    <pre><code class="language-json line-numbers"><textarea readonly> <a title="Csper" href="https://csper.io/" target="_blank" rel="noopener noreferrer">
    <img src="https://assets.csper.io/badges/csper_blue_white.webp" width="130" height="44"  alt="Csper"/>
 </a></textarea>
</code></pre>
    <a title="Csper" href="https://csper.io/" target="_blank" rel="noopener noreferrer">
      <img src="https://assets.csper.io/badges/csper_blue_white.webp" width="130" height="44" alt="Csper" class="my-2" />
    </a>
  </div>
  <h2>Transparent dark badge</h2>
  <div class="bg-opacity p-2 mb-4 mt-2">
    <pre><code class="language-json line-numbers"><textarea readonly> <a title="Csper" href="https://csper.io/" target="_blank" rel="noopener noreferrer">
    <img src="https://assets.csper.io/badges/csper_dark_transparent.webp" width="130" height="44"  alt="Csper"/>
 </a></textarea>
</code></pre>
    <a title="Csper" href="https://csper.io/" target="_blank" rel="noopener noreferrer">
      <img src="https://assets.csper.io/badges/csper_dark_transparent.webp" width="130" height="44" alt="Csper" class="my-2" />
    </a>
  </div>
  <h2>Transparent light badge</h2>
  <div class="bg-opacity p-2 mb-4 mt-2">
    <pre><code class="language-json line-numbers"><textarea readonly> <a title="Csper" href="https://csper.io/" target="_blank" rel="noopener noreferrer">
    <img src="https://assets.csper.io/badges/csper_white_transparent.webp" width="130" height="44"  alt="Csper"/>
 </a></textarea>
</code></pre>
    <a title="Csper" href="https://csper.io/" target="_blank" rel="noopener noreferrer">
      <img src="https://assets.csper.io/badges/csper_white_transparent.webp" width="130" height="44" alt="Csper" class="my-2" />
    </a>
  </div>
</div>
<app-footer></app-footer>
