import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wave-bottom',
  templateUrl: './wave-bottom.component.html'
})
export class WaveBottomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
