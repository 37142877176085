import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "host",
})
export class HostPipe implements PipeTransform {
  transform(hostString: any, includePath?: boolean): string {
    try {
      let u = new URL(hostString);
      if (includePath) {
        return u.origin + u.pathname;
      }
      return u.origin;
    } catch {
      return hostString;
    }
  }
}
