import { Component, OnInit } from "@angular/core";
import { RecommendationService } from "src/app/services/recommendation.service";
import { Evaluation } from "src/app/models/recommendations";
import { Router } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-evaluator",
  templateUrl: "./evaluator.component.html",
  styleUrls: ["./evaluator.component.scss"],
})
export class EvaluatorComponent implements OnInit {
  evaluation: Evaluation;
  previous: Evaluation[];

  errorStr: string;

  constructor(private recommendationService: RecommendationService, private router: Router, private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.titleService.setTitle("Content Security Policy (CSP) Evaluator");
    this.metaService.updateTag({
      name: "description",
      content:
        "Csper evaluator is a content security policy evaluator. It scans any domain for content security policy and provides security recommendations.",
    });

    this.evaluation = { URL: "" } as Evaluation;

    this.recommendationService.getEvaluations().subscribe((es) => (this.previous = es));
  }

  scan() {
    if (this.evaluation.URL == "") {
      this.errorStr = "empty url";
      return;
    }

    if (this.evaluation.URL.indexOf("http") < 0 && this.evaluation.URL.indexOf("https") < 0) {
      this.errorStr = "missing http or https";
      return;
    }

    this.recommendationService.evaluate(this.evaluation).subscribe(
      (evaluation) => {
        this.router.navigate(["evaluations", evaluation.id]);
      },
      (error) => {
        this.errorStr = error;
      }
    );
  }

  reset() {
    this.evaluation = {} as Evaluation;
    this.errorStr = "";
  }

  clear() {
    this.evaluation.recommendations = null;
  }
}
