<div class="container-fluid pt-65px" *ngIf="profile">
  <div>
    <button class="float-right btn btn-danger" (click)="purgeConfirm = true">Purge User</button>
    <button class="float-right btn btn-danger" *ngIf="purgeConfirm" (click)="purgeUser()">Purge Confirm</button>

    <h1>
      {{ profile.user.firstName }} {{ profile.user.lastName }}<br />
      {{ profile.email }} {{ profile.user.authMechanism }}<br />
      {{ profile.user.id }}
    </h1>

    <p>{{ profile.user.ts | timeAgo }}</p>

    <h3>Organizations</h3>
    <table class="table table-condensed table-bordered">
      <tr>
        <th>Plan</th>
        <th>Name</th>
        <th>id</th>
        <th>ts</th>
        <th>Disabled</th>
        <th>Report Count</th>
        <th>quota/last seen</th>
        <th>members</th>
      </tr>
      <ng-container *ngFor="let organization of profile.organizations">
        <tr>
          <td>{{ organization.planName }}</td>
          <td>{{ organization.name }}</td>
          <td>{{ organization.id }}</td>
          <td>{{ organization.ts | timeAgo }}</td>
          <td ngClass="{'bg-success': !organization.isDisabled}">{{ organization.isDisabled }}</td>
          <td>{{ organization.reportCount }}</td>
          <td>{{ organization.reportCountBillingCycle }}</td>
          <td>
            <span *ngFor="let member of organization.members">{{ member.email }} {{ member.role }}</span>
          </td>
          <td>
            <button class="btn btn-danger" (click)="purgeOrganization(organization.id)">Purge</button>
            <button class="btn btn-danger" (click)="closeOrganization(organization.id)">Close</button>
          </td>
        </tr>

        <tr *ngFor="let project of organization.projects">
          <td></td>
          <td>{{ project.name }}</td>
          <td>{{ project.id }}</td>
          <td>{{ project.ts | timeAgo }}</td>
          <td>{{ project.isDisabled }}</td>
          <td>{{ project.reportCount }}</td>
          <td>{{ project.lastReport?.documentURIOrigin || "" }}</td>
          <td>
            <span *ngFor="let member of project.members">{{ member.email }} {{ member.role }}</span>
          </td>
          <td>
            <button class="btn btn-danger" (click)="purgeProject(project.id)">Purge</button>
          </td>
        </tr>
      </ng-container>
    </table>
    <!-- 
            <div class="card-body">

                <table class="table table-condensed table-borderless">
                    <tr *ngFor="let project of organization.projects">
                        <td>{{project.id}}</td>
                        <td>{{project.name}}</td>
                        <td>{{project.ts | timeAgo}}</td>
                        <td>{{project.isDisabled}}</td>
                        <td>{{project.reportCount}}</td>
                        <td>{{project.lastReport?.documentURIOrigin || ""}}</td>
                        <td><span *ngFor="let member of project.members">{{member.email}} {{member.role}}</span></td>
                    </tr>
                </table>
            </div> -->
  </div>

  <h3>Optout</h3>
  <table class="table table-condensed">
    <tr *ngFor="let optout of profile.optouts">
      <td>{{ optout.campaign }}</td>
      <td>{{ optout.email }}</td>
      <td>{{ optout.ts | timeAgo }}</td>
    </tr>
  </table>

  <h3>Notifications</h3>
  <table class="table table-condensed">
    <tr *ngFor="let notification of profile.notifications">
      <td>{{ notification.campaign }}</td>
      <td>{{ notification.ts | timeAgo }}</td>
      <td>{{ notification.subject }}</td>
    </tr>
  </table>

  <h3>Sessions</h3>
  <table class="table table-condensed">
    <tr *ngFor="let session of profile.sessions" (click)="session.isShow = true">
      <td>{{ session.ts | timeAgo }}</td>
      <td>
        <span *ngIf="session.isShow">{{ session.token }}</span>
      </td>
    </tr>
  </table>

  <pre>{{ profile | json }}</pre>
</div>
