import { Component, OnInit } from "@angular/core";
import { RecommendationService } from "src/app/services/recommendation.service";
import { Router } from "@angular/router";
import { Evaluation } from "src/app/models/recommendations";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  errorStr: string;
  evaluation: Evaluation;

  features: any;

  public isMobileLayout = false;

  constructor(private recommendationService: RecommendationService, private router: Router, private titleService: Title, private metaService: Meta) {
    this.evaluation = { URL: "" } as Evaluation;

    this.features = [
      {
        img: "https://assets.csper.io/report-grouping-new.webp",
        title: "Report Grouping",
        description:
          "Similar reports are automatically grouped together — no more sifting through thousands or millions of individual violation reports.",
        alt: "content security policy report grouping",
      },
      {
        img: "https://assets.csper.io/classification-new.webp",
        title: "Report Classifier",
        description: "The report classifier allows you to filter out the reports that don't matter, such as extensions and bots.",
        alt: "content security policy report classification",
      },
      {
        img: "https://assets.csper.io/realtime-new.webp",
        title: "Realtime",
        description: "View your reports as they are happening. Sub-second realtime events allow you to act faster.",
        alt: "content security policy report-uri realtime",
      },
      {
        img: "https://assets.csper.io/alerting-new.webp",
        title: "Alerts",
        description: "Get alerted on suspicious violation reports or when there's a spike of reports.",
        alt: "content security policy alert",
      },
      {
        img: "https://assets.csper.io/policy-evaluator-new.webp",
        title: "Policy Evaluator",
        description: "Get security recommendations and insights on your policies to stay up to date with the latest best practices.",
        alt: "content security policy evaluation security",
      },
      {
        img: "https://assets.csper.io/policy-generator-new.webp",
        title: "Automatic Policy Generator",
        description: "Csper automatically generates secure policies based on previously seen reports.",
        alt: "automatic content security policy builder",
      },
    ];
  }

  ngOnInit() {
    this.titleService.setTitle("Content Security Policy made easy");
    this.metaService.updateTag({
      name: "description",
      content:
        "Content-Security-Policy made easy. Csper is a content security policy violation report endpoint. Csper gives website owners insight and recommendations based off the csp policy and reports",
    });

    // var typed = new Typed('#typed', {
    //   stringsElement: '#typed-strings'
    // });

    this.isMobileLayout = window.innerWidth <= 991;
    window.onresize = () => (this.isMobileLayout = window.innerWidth <= 991);
  }

  scan() {
    if (this.evaluation.URL == "") {
      this.errorStr = "empty url";
      return;
    }

    if (this.evaluation.URL.indexOf("http") < 0 && this.evaluation.URL.indexOf("https") < 0) {
      this.errorStr = "missing http or https";
      return;
    }

    this.recommendationService.evaluate(this.evaluation).subscribe(
      (evaluation) => {
        this.router.navigate(["evaluations", evaluation.id]);
      },
      (error) => {
        // console.log("HERE", error)
        this.errorStr = error;
      }
    );
  }
}

// scan() {
//   if (this.evaluation.URL == "") {
//     this.errorStr = "empty url"
//     return
//   }

//   if (this.evaluation.URL.indexOf("http") < 0 && this.evaluation.URL.indexOf("https") < 0) {
//     this.errorStr = "missing http or https"
//     return
//   }

//   this.recommendationService.evaluate(this.evaluation).subscribe((evaluation) => {
//     this.router.navigate(["evaluations", evaluation.id])
//   }, (error) => {
//     this.errorStr = error.error
//   })
// }

// reset() {
//   this.evaluation = {} as Evaluation
//   this.errorStr = ""
// }

// clear() {
//   this.evaluation.recommendations = null
// }
