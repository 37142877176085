import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-report-uri",
  templateUrl: "./report-uri.component.html",
  styleUrls: ["./report-uri.component.scss"],
})
export class ReportUriComponent implements OnInit {
  features: any;

  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.titleService.setTitle("Report Collector");
    this.metaService.updateTag({
      name: "description",
      content:
        "Content Security Policy report collector using report-uri endpoint. Includes classifications, aggregations, analysis, alerts and more.",
    });

    this.features = [
      {
        img: "https://assets.csper.io/report-grouping-new.webp",
        title: "Report Grouping",
        description:
          "Similar reports are automatically grouped together — no more sifting through thousands or millions of individual violation reports.",
        alt: "content security policy report grouping",
      },
      {
        img: "https://assets.csper.io/classification-new.webp",
        title: "Report Classifier",
        description: "The report classifier allows you to filter out the reports that don't matter, such as extensions and bots.",
        alt: "content security policy report classification",
      },
      {
        img: "https://assets.csper.io/realtime-new.webp",
        title: "Realtime",
        description: "View your reports as they are happening. Sub-second realtime events allow you to act faster.",
        alt: "content security policy report-uri realtime",
      },
      {
        img: "https://assets.csper.io/alerting-new.webp",
        title: "Alerts",
        description: "Get alerted on suspicious violation reports or when there's a spike of reports.",
        alt: "content security policy alert",
      },
      {
        img: "https://assets.csper.io/policy-evaluator-new.webp",
        title: "Policy Evaluator",
        description: "Get security recommendations and insights on your policies to stay up to date with the latest best practices.",
        alt: "content security policy evaluation security",
      },
      {
        img: "https://assets.csper.io/policy-generator-new.webp",
        title: "Automatic Policy Generator",
        description: "Csper automatically generates secure policies based on previously seen reports.",
        alt: "automatic content security policy builder",
      },
    ];
  }
}
