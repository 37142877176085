<div class="container-fluid pt-65px">
  <div class="row">
    <nav class="col-md-3 d-none d-md-block bg-light sidebar">
      <div class="sidebar-sticky">
        <ul class="nav flex-column pl-4">
          <ng-container *ngFor="let toc of tocs; index as i">
            <h6 *ngIf="toc.isHeading" class="d-flex justify-content-between align-items-center px-1 pt-3 mb-0 text-muted">
              <span>{{ toc.title }}</span>
            </h6>

            <li *ngIf="!toc.isHeading" class="nav-item p-0">
              <a class="nav-link py-1" [routerLink]="['/docs', toc.id]" routerLinkActive="active">
                {{ toc.title }}
              </a>
            </li>
          </ng-container>

          <!-- <h6 class="d-flex justify-content-between align-items-center px-3 pt-3 mb-1 text-muted">
                        <span>OVERVIEW</span>
                    </h6>

                    <li class="nav-item">
                        <a class="nav-link" routerLink="overview" routerLinkActive="active">
                            Csper
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" routerLink="overview" routerLinkActive="active">
                            How it works
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" routerLink="overview" routerLinkActive="active">
                            Getting Started
                        </a>
                    </li>


                    <h6 class="d-flex justify-content-between align-items-center px-3 pt-3 mb-1 text-muted">
                        <span>PRODUCT</span>
                    </h6>

                    <li class="nav-item">
                        <a class="nav-link" routerLink="overview" routerLinkActive="active">
                            Swag
                        </a>
                    </li> -->
        </ul>
      </div>
    </nav>

    <main role="main" class="col-md-9 ml-sm-auto col-lg-9 px-0">
      <div class="px-4">
        <ng-container *ngIf="page">
          <h1>{{ page.title }}</h1>
          <app-content [contents]="page.content"></app-content>
        </ng-container>

        <p *ngIf="errorStr" class="text-danger">
          {{ errorStr }}
        </p>
      </div>

      <div class="p-2"></div>
    </main>
  </div>
</div>
