import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  errorString: string;
  successString: string;
  user: User;

  constructor(private userService: UserService) {
    this.user = {} as User;
  }

  ngOnInit(): void {}

  forgotPassword(): void {
    console.log(this.user);
    this.userService.forgotPassword(this.user).subscribe(
      () => {
        this.successString = "If this was a valid account, an email has been sent with the reset link.";
      },
      (error) => {
        this.errorString = error;
      }
    );
  }
}
