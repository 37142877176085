<div class="dropdown pt-3 px-2">
  <button
    class="dropdown-toggle btn btn-outline-dark btn-block text-wrap"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    data-display="static"
  >
    {{ currentName }}
  </button>

  <div class="dropdown-menu dropdown-menu-left ml-1" style="width: 96%">
    <!-- <div class="px-3 pt-1">
                                <div class="form-group">
                                    <label>Search Projects</label>
                                    <input type="text" class="form-control" placeholder="search" [(ngModel)]="searchString">
                                </div>
                            </div> -->
    <!-- <div class=" dropdown-divider"></div> -->
    <ng-container *ngFor="let organization of organizations | projectOrgSearch: searchStr">
      <!-- org name -->
      <a class="dropdown-item text-wrap" [class.font-weight-bold]="organization.id == currentID" [routerLink]="['/org', organization.id, 'projects']"
        >{{ organization.name }}
        <span *ngIf="organization.id == currentID">(current)</span>
      </a>

      <ng-container *ngFor="let project of organization.projects">
        <a class="dropdown-item text-wrap pl-5" [routerLink]="['/p', project.id, 'analysis']" [class.font-weight-bold]="project.id == currentID">
          {{ project.name }}
          <span *ngIf="project.id == currentID">(current)</span>
        </a>
      </ng-container>
      <div class="dropdown-divider"></div>
    </ng-container>

    <div class="px-3">
      <button class="btn btn-light btn-sm" routerLink="/organizations">View All</button>
    </div>
  </div>
</div>
