<div class="pt-65px">
  <!--  -->
  <div class="container pt-5 pb-3">
    <div class="row">
      <div class="col-md-6">
        <h2 class="display-4 m-0">Policy Generator<span class="badge text-primary" style="font-size: 14px;vertical-align: top">Free</span></h2>
        <p class="lead pb-4">Generate a Content Security Policy in minutes with our browser extensions for free, then view the results on Csper.</p>
        <p class="lead">
          <a
            class="btn btn-info mb-3"
            href="https://chrome.google.com/webstore/detail/content-security-policy-c/ahlnecfloencbkpfnpljbojmjkfgnmdc"
            style="width: 300px"
          >
            <img src="https://assets.csper.io/logo-chrome-colors.svg" class="size-24px" alt="chrome extension" />
            View on Chrome Extension Store</a
          >

          <a class="btn btn-info mb-2" href="https://addons.mozilla.org/en-US/firefox/addon/csp-generator/" style="width: 300px">
            <img src="https://assets.csper.io/logo-firefox-color.svg" class="size-24px" alt="firefox extension" />
            View on Firefox Addon Store</a
          >
        </p>
      </div>
      <div class="col-md-6">
        <div class="text-center">
          <img src="https://assets.csper.io/csper_demo_short_1.webp" class="img-fluid rounded shadow" alt="Content Security Policy (CSP) generator" />
          <p>
            <small>Content Security Policy (CSP) Generator Browser Extension.</small>
          </p>
        </div>
      </div>
    </div>
  </div>
 <app-wave-one></app-wave-one>
  <div class="p-3"></div>

  <div class="container">
    <h2 class="text-center">How it works</h2>

    <p class="py-3">
      Under the hood the extension injects a temporary content security policy in report-only mode, and then uses the violation reports from
      report-uri to create a policy.
    </p>

    <div class="row pt-3">
      <div class="col-md-6 order-card-2">
        <img src="https://assets.csper.io/generator-1.webp" alt="start csp policy generator" class="rounded shadow img-fluid" />
      </div>
      <div class="col-md-6 order-card-1" style="align-self: center">
        <h4 class="text-dark">1. Enable on a specific website</h4>
        <p>
          The extension needs to be enabled on a website first. One enabled the extension will start collecting information on the page (using a
          policy in report-only mode). The tool works on a per-domain basis.
        </p>
      </div>
    </div>
    <div class="p-3"></div>

    <div class="row pt-3">
      <div class="col-md-6" style="align-self: center">
        <h4 class="text-dark">2. Visit a couple of pages</h4>
        <p>
          The extension is only able to generate a policy for the content that it sees. It's not critical to visit every page on the domain, but the
          better the policy is now, the less work for later.
        </p>
      </div>
      <div class="col-md-6">
        <img src="https://assets.csper.io/generator-2.webp" alt="collect csp reports" class="rounded shadow img-fluid" />
      </div>
    </div>
    <div class="p-3"></div>

    <div class="row pt-3">
      <div class="col-md-6 order-card-2">
        <img src="https://assets.csper.io/generator-3.webp" alt="view inline reports" class="rounded shadow img-fluid" />
      </div>

      <div class="col-md-6 order-card-1" style="align-self: center">
        <h4 class="text-dark">3. Fix/Inspect inline reports</h4>
        <p>
          The extension generates a list of all inline reports that need to be fixed before the policy can be put in enforce mode. You can either
          start moving these inline resources to their own file now or later.
        </p>
      </div>
    </div>

    <div class="p-3"></div>

    <div class="row pt-3">
      <div class="col-md-6" style="align-self: center">
        <h4 class="text-dark">4. All done!</h4>
        <p>The policy is generated! It's best to set this policy in report-only mode for a few days to capture the remaining missed content.</p>

        <p>The extension allows you to email the results to yourself/your team for follow up.</p>

        <p>You can also view the results within Csper to get more insight about the reports.</p>
      </div>
      <div class="col-md-6">
        <img src="https://assets.csper.io/generator-4.webp" alt="generated csp policy" class="rounded shadow img-fluid" />
      </div>
    </div>
  </div>

  <div class="p-3"></div>

<app-wave-top></app-wave-top>
  <div class="bg-opacity">
    <div class="container pt-3">
      <h2 class="text-center">Download Now</h2>
      <p class="lead text-center">
        <a
          class="btn btn-info m-2"
          href="https://chrome.google.com/webstore/detail/content-security-policy-c/ahlnecfloencbkpfnpljbojmjkfgnmdc"
        >
          <img src="https://assets.csper.io/logo-chrome-colors.svg" class="size-24px" />
          View on Chrome Extension Store</a
        >

        <a class="btn btn-info m-2" href="https://addons.mozilla.org/en-US/firefox/addon/csp-generator/">
          <img src="https://assets.csper.io/logo-firefox-color.svg" class="size-24px" />
          View on Firefox Addon Store</a
        >
      </p>
    </div>
    <app-wave-three></app-wave-three>
  </div>

  <app-footer></app-footer>
</div>
