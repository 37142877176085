import { Component, OnInit, Input } from "@angular/core";
import { Subscriber } from "src/app/models/subscriber";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "src/app/services/config.service";

@Component({
  selector: "app-email-subscribe",
  templateUrl: "./email-subscribe.component.html",
  styleUrls: ["./email-subscribe.component.css"],
})
export class EmailSubscribeComponent implements OnInit {
  subscriber: Subscriber;
  message: string;
  errorStr: string;
  @Input() isCondenced: boolean;


  constructor(private http: HttpClient, private config: ConfigService) {}

  ngOnInit(): void {
    this.subscriber = {
      source: "web",
      link: window.location.toString(),
    } as Subscriber;
  }

  saveSubscription() {
    return this.http.post<Subscriber>(`${this.config.getOrigin()}/api/optin`, this.subscriber).subscribe(
      () => {
        this.message = "Subscribed!";
        this.errorStr = "";
      },
      (error) => {
        this.message = "";
        this.errorStr = error;
      }
    );
  }
}
