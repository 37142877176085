import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  errorString: string;
  successString: string;
  user: User;
  token: string;

  constructor(private userService: UserService, private route: ActivatedRoute) {
    this.user = {} as User;
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get("token");
    // console.log(this.token)
  }

  resetPassword(): void {
    if (this.user.email != this.user.password) {
      this.errorString = "Passwords do not match";
      return;
    }

    this.userService.resetPassword(this.user.password, this.token).subscribe(
      () => {
        this.successString = "Password reset successful.";
      },
      (error) => {
        this.errorString = error;
      }
    );
  }
}
