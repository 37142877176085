<div *ngFor="let content of contents">
  <div *ngIf="content.contentType == 'HTML'" [innerHTML]="content.htmlContent.html"></div>

  <div *ngIf="content.contentType == 'IMG'" class="text-center">
    <img src="{{ content.imgContent.src }}" class="content-img p-1" alt="{{ content.imgContent.alt }}" width="{{content.imgContent.width || '100%'}}" height="{{content.imgContent.height || 'auto'}}" style="width: 100%; height: auto;"/>
    <p>
      <small>{{ content.imgContent.caption }}</small>
    </p>
  </div>

  <ng-container *ngIf="content.contentType == 'POLICY'">
    <div class="pb-3">
      <app-policy-view [policyStr]="content.policyContent.policy"></app-policy-view>
    </div>
  </ng-container>

  <div *ngIf="content.contentType == 'EMAIL'">
    <app-email-subscribe></app-email-subscribe>
  </div>

  <div *ngIf="content.contentType == 'CTA'">
    <div class="py-2">
      <app-email-subscribe></app-email-subscribe>
    </div>
  </div>

  <ng-container *ngIf="content.contentType == 'TOC'">
    <ul>
      <li *ngFor="let a of content.tocContent.toc">
        <a [routerLink]="[]" [fragment]="a.id">{{ a.title }}</a>
        <ul *ngIf="a.children?.length">
          <li *ngFor="let b of a.children">
            <a [routerLink]="[]" [fragment]="b.id">{{ b.title }}</a>
            <ul *ngIf="b.children?.length">
              <li *ngFor="let c of b.children">
                <a [routerLink]="[]" [fragment]="c.id">{{ c.title }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </ng-container>

  <div *ngIf="content.contentType == 'TEXT'">
    <p>{{ content.textText }}</p>
  </div>

  <div *ngIf="content.contentType == 'VIDEO'" class="text-center">
    <div class="embed-responsive embed-responsive-16by9">
      <iframe class="embed-responsive-item" [src]="content.videoContent.url" allowfullscreen></iframe>
    </div>
  </div>

  <div *ngIf="content.contentType == 'RELATED'">
    <div class="card-deck">
      <div
        class="card mb-3"
        *ngFor="let related of content.relatedContent.related"
        [class.shadow-lg]="related.hover"
        (mouseover)="related.hover = true"
        (mouseout)="related.hover = false"
        [routerLink]="[related.isBlog ? '/blog' : '/docs', related.slug]"
      >
        <div class="card-body">
          <h5 class="card-title mb-0">
            <a class="content-body-link" [routerLink]="[related.isBlog ? '/blog' : '/docs', related.slug]">{{ related.title }}</a>
          </h5>
          <p class="card-text">
            <small class="text-muted">{{ related.description }}</small>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="content.contentType == 'CTA_PRODUCT'">
    <div class="card bg-opacity border-0">
      <div class="row no-gutters p-2" style="align-items: center">
        <div class="col-md-9 card-body p-2">
          <h5 class="card-title">Looking for tools to make CSP easier?</h5>
          <p class="card-text">
            Csper has the tools to help you understand, deploy and manage your content security policy. Get started in minutes. Report aggregations,
            classification, analysis, alerting, realtime and more. Free 14 day trial.
          </p>
        </div>
        <div class="col-md-3 card-body p-2 text-center">
          <button class="btn float-md-right btn-secondary btn-lg" [routerLink]="['/report-uri']" role="button">Learn More</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="content.contentType == 'CTA_TRIAL'">
    <div class="card">
      <div class="card-body">
        <button class="btn float-right btn-secondary btn-lg" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }" role="button">
          Start Free Csper Trial
        </button>
        <h5 class="card-title">Ready to start?</h5>
        <p class="card-text">
          Csper has the tools to help you understand, deploy and manage your content security policy. Get started in minutes. Report aggregations,
          classification, analysis, alerting, realtime and more. Free 14 day trial.
        </p>
      </div>
    </div>
  </div>
</div>
