<div class="progress">
  <div
    class="progress-bar text-center"
    role="progressbar"
    [style.width]="(current * 100) / limit + '%'"
    aria-valuenow="25"
    [class.bg-warning]="current / limit > 0.9 && current / limit < 1"
    [class.bg-danger]="current > limit"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    {{ current }} / {{ limit }}
  </div>
</div>
