import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import StackdriverErrorReporter from "stackdriver-errors-js";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  lastError$: Subject<string>;
  stackDriverErrorHandler: any;

  constructor() {
    this.lastError$ = new Subject<string>();
  }

  handleError(error: string) {
    console.error(error);
    this.lastError$.next(error);
  }

  reportError(error: any) {
    if (environment.production) {
      this.stackDriverErrorHandler.report(error);
    }
  }

  clearError() {
    this.lastError$.next("");
  }

  enableStackDriver() {
    if (environment.production) {
      this.stackDriverErrorHandler = new StackdriverErrorReporter();
      this.stackDriverErrorHandler.start({
        key: environment.stackDriverAPIKey,
        projectId: environment.stackDriverProjectID,
      });
    }
  }
}
