import { Component, OnInit } from "@angular/core";
import { Feedback } from "src/app/models/feedback";
import { ProjectService } from "src/app/services/project.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent implements OnInit {
  comment: Feedback;
  commentSent: boolean;

  constructor(private projectService: ProjectService, private userService: UserService) {}

  ngOnInit() {
    this.comment = {} as Feedback;

    this.userService.user$.subscribe((me) => {
      this.comment.email = me.email;
      this.comment.first = me.firstName;
      this.comment.last = me.lastName;
    });
  }

  saveComment() {
    this.projectService.sendFeedback(this.comment).subscribe(() => {
      this.commentSent = true;
    });
  }
}
