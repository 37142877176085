<div class="pt-65px"></div>

<div class="container">
  <h1 *ngIf="evaluation.recommendations" class="overflow-hidden">
    {{ evaluation.URL }}
  </h1>
  <a routerLink="/evaluator">back</a>

  <div *ngIf="evaluation.recommendations">
    <!-- <pre>{{evaluation.parsedPolicy | json}}</pre> -->

    <app-policy-view [parsedPolicy]="evaluation.parsedPolicy" [mode]="'pretty'"></app-policy-view>

    <div class="row" *ngIf="evaluation.recommendations">
      <div class="col-md-6">
        <table class="table">
          <tr>
            <th>High</th>
            <th>Medium</th>
            <th>Low</th>
            <th>Info</th>
          </tr>
          <tr>
            <td class="table-danger">
              {{ evaluation.stats.totalHigh }}
            </td>
            <td class="table-warning">
              {{ evaluation.stats.totalMedium }}
            </td>
            <td class="table-active">
              {{ evaluation.stats.totalLow }}
            </td>
            <td class="table-info">
              {{ evaluation.stats.totalInfo }}
            </td>
          </tr>
        </table>
      </div>

      <div class="col-md-6">
        <p class="pt-1">
          Time: <strong>{{ evaluation.ts | timeAgo }}</strong>
        </p>
        <p>
          Source: <strong>{{ evaluation.source }}</strong>
        </p>
        <p>
          Disposition: <strong>{{ evaluation.disposition }}</strong>
        </p>
        <p>
          Policies: <strong>{{ evaluation.policies.length }}</strong>
        </p>
        <p>
          Total Findings:
          <strong>{{ evaluation.recommendations.length }}</strong>
        </p>
      </div>
    </div>

    <h3>Scan Results</h3>
    <!-- <div *ngFor="let recommendation of evaluation.recommendations.recommendationsFromPolicy" class="card" [ngClass]="{
        'text-white bg-danger': recommendation.severity == 'HIGH',
        'text-white bg-warning': recommendation.severity == 'MEDIUM'
    }"> -->
    <div *ngFor="let recommendation of evaluation.recommendations" class="card">
      <div class="card-header">
        <div class="row">
          <span
            class="col-2"
            [ngClass]="{
              'text-danger': recommendation.severity == 'HIGH',
              'text-warning': recommendation.severity == 'MEDIUM',
              'text-active': recommendation.severity == 'WARN',
              'text-info': recommendation.severity == 'INFO'
            }"
          >
            {{ recommendation.severity }}
          </span>
          <div class="col-6">
            {{ recommendation.title }}
          </div>
          <div class="col-2">
            {{ recommendation.directive }}
          </div>
          <div class="col-2">
            <button class="btn btn-primary btn-sm" (click)="recommendation.expand = !recommendation.expand">Details</button>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="recommendation.expand">
        <div class="row">
          <div class="col-sm-6">
            <app-policy-view
              [parsedPolicy]="evaluation.parsedPolicy"
              [directive]="recommendation.directive"
              [highlights]="[recommendation.source]"
              [mode]="'pretty'"
            ></app-policy-view>
          </div>

          <div class="col-sm-6">
            <p>
              Directive: <strong>{{ recommendation.directive }}</strong>
            </p>
            <p>
              Source: <strong>{{ recommendation.source }}</strong>
            </p>

            <p><strong>Why care?</strong></p>
            <p>{{ recommendation.message }}</p>

            <p><strong>Recommendation</strong></p>
            <p>{{ recommendation.recommendation }}</p>

            Docs:
            <a href="{{ convertDocLink(recommendation.docs) }}" class="card-link" target="_blank">{{ recommendation.docsTitle }}</a>
          </div>
        </div>

        <!-- <pre>{{recommendation | json}} </pre> -->
      </div>
    </div>
  </div>

  <div class="card mt-4">
    <div class="card-body">
      <button class="btn float-right btn-secondary btn-lg" [routerLink]="['/report-uri']" role="button">Learn More</button>
      <h5 class="card-title">Looking for more tools to make CSP easier?</h5>
      <p class="card-text">
        Csper has the tools to help you understand, deploy and manage your content security policy. Get started in minutes. Report aggregations,
        classification, analysis, alerting, realtime and more. Free 14 day trial.
      </p>
    </div>
  </div>
</div>

<div class="py-2"></div>

<app-footer></app-footer>
