import { Component, OnInit, Sanitizer, SecurityContext, AfterViewInit } from "@angular/core";
import { Page, PageContent } from "src/app/models/cms";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, Title, Meta } from "@angular/platform-browser";
import { PageService } from "src/app/services/page.service";
import { ConfigService } from "src/app/services/config.service";

@Component({
  selector: "app-blog-post",
  templateUrl: "./blog-post.component.html",
  styleUrls: ["./blog-post.component.css"],
})
export class BlogPostComponent implements OnInit {
  posts: Page[];

  slug: string;

  post: Page;

  constructor(
    private pageService: PageService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.slug = this.route.snapshot.paramMap.get("slug");

    this.pageService.getPage(this.slug).subscribe(
      (page) => {
        this.loadPost(page);
      },
      (e) => {
        this.router.navigate(["/blog"]);
      }
    );
  }

  loadPost(post: Page) {
    this.post = post;

    this.titleService.setTitle(this.post.title);

    this.metaService.updateTag({
      name: "description",
      content: this.post.description,
    });

    this.metaService.removeTag("property='og:image'");

    this.metaService.updateTag({
      property: "og:title",
      content: this.post.title,
    });
    this.metaService.updateTag({ property: "og:type", content: "article" });
    this.metaService.updateTag({
      property: "og:url",
      content: this.configService.getOrigin() + "/blog/" + this.post.slug,
    });
    this.metaService.updateTag({
      property: "og:image",
      content: this.configService.getOrigin() + this.post.coverImage,
    });
    this.metaService.updateTag({
      name: "og:image",
      content: this.configService.getOrigin() + this.post.coverImage,
    });
    this.metaService.updateTag({
      property: "twitter:image",
      content: this.post.coverImage,
    });
    this.metaService.updateTag({
      property: "og:description",
      content: this.post.description,
    });

    this.metaService.updateTag({
      name: "twitter:title",
      content: this.post.title,
    });
    this.metaService.updateTag({
      name: "og:description",
      content: this.post.description,
    });
    this.metaService.updateTag({
      name: "twitter:card",
      content: "summary_large_image",
    });
    this.metaService.updateTag({
      name: "twitter:image",
      content: this.configService.getOrigin() + this.post.coverImage,
    });
  }
}
