import { Component, OnInit } from "@angular/core";
import { TrackService } from "./services/track.service";
import { environment } from "src/environments/environment";
import { NavigationStart, Router } from "@angular/router";
import { LoadingBarService } from "@ngx-loading-bar/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(private trackService: TrackService, private router: Router, private loadingBar: LoadingBarService) {}

  ngOnInit() {
    if (environment.production) {
      this.trackService.enableGA();
      // this.trackService.enableHotJar();
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.loadingBar.complete();
          this.router.navigate([event.url.replace("/#", "")]);
        }
      }
    });
  }
}
