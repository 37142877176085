<div [class.card]="!isCondenced" [class.col-sm-7]="isCondenced" [class.m-auto]="isCondenced">
  <div class="card-body">
    <h5 class="card-title">Subscribe for updates?</h5>
    <p class="card-text">Stay up to date with the latest Content Security Policy news, product updates, discounts, and more!</p>
    <div class="input-group mb-3">
      <input type="text" class="form-control" [(ngModel)]="subscriber.email" placeholder="Email" aria-label="Email" />
      <div class="input-group-append">
        <button class="btn btn-primary" type="button" (click)="saveSubscription()">Subscribe</button>
      </div>
    </div>
    <p *ngIf="message" class="text-success">{{ message }}</p>
    <p *ngIf="errorStr" class="text-danger">{{ errorStr }}</p>
  </div>
</div>
