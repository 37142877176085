<div class="position-relative" *ngIf="parsedPolicy">
  <div *ngIf="displayMode == 'small'">
    <textarea rows="1" class="form-control" readonly [(ngModel)]="policyStr"></textarea>
  </div>

  <div *ngIf="displayMode == 'pretty'">
    <div class="card">
      <div class="card-body">
        <div *ngFor="let directive of parsedPolicy.directiveOrder" class="policy-directive">
          <span class="text-primary">{{ directive }}</span>
          <span *ngFor="let source of parsedPolicy.directives[directive]">
            <span
              [ngClass]="{
                'text-danger': parsedPolicy.sourceMapping[source] == 'highlight',
                'text-warning': parsedPolicy.sourceMapping[source] == 'scheme-source',
                'text-info': parsedPolicy.sourceMapping[source] == 'keyword-source',
                'text-grey': parsedPolicy.sourceMapping[source] == 'host-source',
                'text-success': parsedPolicy.sourceMapping[source] == 'nonce-source' || parsedPolicy.sourceMapping[source] == 'hash-source'
              }"
            >
              {{ source }}</span
            >
          </span>
          <span class="text-muted">;</span>

          <br />
        </div>
      </div>
    </div>

    <!-- <textarea class="form-control" disabled autosize>{{pretty}}</textarea> -->
  </div>

  <div *ngIf="displayMode == 'full'">
    <textarea class="form-control" readonly autosize>{{ policyStr }}</textarea>
  </div>
  <div id="controls" class="btn-group btn-group-toggle float-right btn-sm" data-toggle="buttons">
    <label class="btn btn-secondary btn-sm policy-view-button" (click)="displayMode = 'full'"> <input type="radio" name="options" /> Inline </label>
    <label class="btn btn-secondary btn-sm policy-view-button" (click)="displayMode = 'pretty'"> <input type="radio" name="options" /> Pretty </label>
  </div>
</div>
