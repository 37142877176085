import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Policy } from "../models/policy";
import { ConfigService } from "./config.service";
import { ParsedPolicy } from "../models/recommendations";

@Injectable({
  providedIn: "root",
})
export class PolicyService {
  directives: string[];

  constructor(private http: HttpClient, private config: ConfigService) {
    this.directives = [
      "default-src",
      "child-src",
      "connect-src",
      "font-src",
      "frame-src",
      "img-src",
      "manifest-src",
      "media-src",
      "object-src",
      "prefrech-src",
      "script-src",
      "style-src",
      "prefetch-src",
      "webrtc-src",
      "worker-src",
      "base-uri",
      "plugin-types",
      "sandbox",
      "disown-opener",
      "form-action",
      "frame-ancestors",
      "navigate-to",
      "report-uri",
      "report-to",
      "block-all-mixed-content",
      "referrer",
      "require-sri-for",
      "upgrade-insecure-requests",
    ];
  }

  defaultPolicy(endpointURL: string): string {
    return (
      "default-src 'self'; script-src 'self' 'report-sample'; style-src 'self' 'report-sample'; base-uri 'self'; object-src 'none'; connect-src 'self'; font-src 'self'; frame-src 'self'; img-src 'self'; manifest-src 'self'; media-src 'self'; worker-src 'none'; report-uri " +
      endpointURL +
      ";"
    );
  }

  parsePolicy(policy: string): Observable<ParsedPolicy> {
    return this.http.post<ParsedPolicy>(`${this.config.getOrigin()}/api/policies/parse`, { policy: policy });
  }

  getPolicies(projectID: string): Observable<Policy[]> {
    return this.http.get<Policy[]>(`${this.config.getOrigin()}/api/projects/${projectID}/policies`);
  }

  getPoliciesStats(projectID: string): Observable<Policy[]> {
    return this.http.get<Policy[]>(`${this.config.getOrigin()}/api/projects/${projectID}/policies/stats`);
  }

  getLatestPolicy(projectID: string): Observable<Policy> {
    return this.http.get<Policy>(`${this.config.getOrigin()}/api/projects/${projectID}/policies/latest`);
  }

  getLatestPolicyVersion(projectID: string): Observable<Policy> {
    return this.http.get<Policy>(`${this.config.getOrigin()}/api/projects/${projectID}/policies/latest/version`);
  }

  getPolicy(projectID: string, policyID: string): Observable<Policy> {
    return this.http.get<Policy>(`${this.config.getOrigin()}/api/projects/${projectID}/policies/${policyID}`);
  }

  updatePolicy(policy: Policy): Observable<Policy> {
    return this.http.put<Policy>(`${this.config.getOrigin()}/api/projects/${policy.projectID}/policies/${policy.id}`, policy);
  }

  savePolicy(policy: Policy): Observable<Policy> {
    return this.http.post<Policy>(`${this.config.getOrigin()}/api/projects/${policy.projectID}/policies`, policy);
  }

  getMatchingPolicy(policies: Policy[], policyIDs: string[]) {
    let out = [];
    for (let id of policyIDs) {
      for (let p of policies) {
        if (p.id == id) {
          out.push(p);
        }
      }
    }
    return out;
  }
}
