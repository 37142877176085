import { Component } from "@angular/core";
import { BillingOverage } from "src/app/models/billing";
import { AdminService } from "src/app/services/admin.service";

@Component({
  selector: "app-admin-billing-overage",
  templateUrl: "./admin-billing-overage.component.html",
  styleUrls: ["./admin-billing-overage.component.css"],
})
export class AdminBillingOverageComponent {
  billingOverages: BillingOverage[] = [];

  constructor(private adminService: AdminService) {}

  ngOnInit() {
    this.adminService.getBillingOverages().subscribe((overages) => {
      this.billingOverages = overages;
    });
  }
}
