import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BillingService } from "src/app/services/billing.service";
import { UserService } from "src/app/services/user.service";
import { Title, Meta } from "@angular/platform-browser";
import { Organization } from "src/app/models/organization";
import { OrganizationService } from "src/app/services/organization.service";
import { Plan } from "src/app/models/plan";
import { environment } from "src/environments/environment";
import { TrackService } from "src/app/services/track.service";

declare var Stripe: any;

@Component({
  selector: "app-new-organization",
  templateUrl: "./new-organization.component.html",
  styleUrls: ["./new-organization.component.css"],
})
export class NewOrganizationComponent implements OnInit {
  organization: Organization;
  plans: Plan[];

  errorStr: string;

  hasQueryPlan: boolean;
  constructor(
    private router: Router,
    private billingService: BillingService,
    private route: ActivatedRoute,
    private userService: UserService,
    private titleService: Title,
    private metaService: Meta,
    private organizationService: OrganizationService,
    private trackService: TrackService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("New Project");
    this.metaService.updateTag({
      name: "description",
      content: "Get started deploying and monitoring content-security-policy on a new domain.",
    });

    // Insert Stripe
    var tag = document.createElement("script");
    tag.src = "https://js.stripe.com/v3/";
    document.getElementsByTagName("head")[0].appendChild(tag);

    this.organization = {} as Organization;

    this.hasQueryPlan = this.route.snapshot.queryParamMap.get("plan") != null;

    this.userService.isLoggedIn$.subscribe(
      (loggedIn) => {
        if (loggedIn) {
          // XXX: Highlight this plan, or default to BUSINESS
          this.organization.planName = this.route.snapshot.queryParamMap.get("plan");
          this.billingService.getPlans().subscribe((p) => {
            this.plans = p;
          });
        } else {
          this.userService.redirectRegister();
        }
      },
      () => {
        this.userService.redirectRegister();
      }
    );
  }

  selectPlan(planName: string) {
    this.organization.planName = planName;
    this.newProject();
  }

  newProject() {
    this.organizationService
      .newOrganization({
        name: this.organization.name || "My Organization",
        planName: this.organization.planName,
      } as Organization)
      .subscribe(
        (org) => {
          this.trackService.trackStartCheckout(org);
          const stripe = Stripe(environment.stripeKey);

          stripe
            .redirectToCheckout({
              sessionId: org.checkoutSessionID,
            })
            .then(function (result) {
              console.log(result);
            });
        },
        (error) => {
          this.errorStr = error.error;
        }
      );
  }
}
