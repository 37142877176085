<div class="container pt-65px">
  <div class="text-center pt-3">
    <h1 class="display-4">Report Collector</h1>
    <p class="lead">Content Security Policy report-uri endpoint. Classification, aggregation, analysis, alerting and more.</p>
    <p class="lead mb-0 pt-3">
      <a class="btn btn-primary btn-lg mr-2" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }" role="button">Start Free Trial</a>
      <a class="btn btn-outline-primary btn-lg" [routerLink]="['/pricing']" [queryParams]="{ redirect: '/new' }" role="button">View pricing</a>
    </p>

    <div class="p-2"></div>

    <img src="https://assets.csper.io/csper-product.png" class="w-100" alt="csper report dashboard" />
  </div>
</div>

<!-- features -->
<app-wave-two></app-wave-two>
<div class="bg-opacity">
  <div class="container text-center">
    <h2 class="pt-5">Features</h2>
    <p class="pb-3">Advance features to help you deploy and monitor CSP in minutes.</p>
    <div class="row m-0 p-3">
      <div class="col-md-4" *ngFor="let feature of features">
        <div class="p-2 mb-0 text-dark rounded-top font-weight-bold">
          {{ feature.title }}
        </div>
        <div class="pb-2">
          <img src="{{ feature.img }}" class="img-fluid img-thumbnail rounded-0 w-100 mb-2 border-0 shadow" alt="{{ feature.alt }}" />
          <p class="text-gray pb-2">{{ feature.description }}</p>
        </div>
      </div>
    </div>
  </div>
 <app-wave-bottom></app-wave-bottom>
</div>

<div class="container">
  <h2 class="text-center pt-3 pb-4">How It Works</h2>
  <p>
    Content Security Policy has a feature called
    <a routerLink="/docs/report-uri">report-uri</a>. When report-uri is enabled, browsers will send a JSON payload to a specified web endpoint
    whenever there's a violation. Csper collects these JSON payloads and performs analysis on them.
  </p>

  <p>
    Getting started with report-uri is as simple as adding a url to your existing content-security-policy. If your website doesn't use content
    security policy, then it's as simple as setting an HTTP header. Csper helps website owners get started either way.
  </p>
</div>

<div class="p-3"></div>

<div class="container">
  <h2 class="text-center pt-3 pb-4">Product Overview</h2>

  <div class="row">
    <div class="col-md-6">
      <img src="https://assets.csper.io/policy_collector_view_selected.webp" class="img-fluid rounded img-thumbnail border-0 shadow mb-4" />
    </div>
    <div class="col-md-6">
      <ul>
        <li class="p-1 list-group-item border-0"><strong>Realtime</strong>: A dashboard showing incoming reports as they are received.</li>
        <li class="p-1 list-group-item border-0"><strong>Reports</strong>: Search through reports by classification, directive, policy and more.</li>
        <li class="p-1 list-group-item border-0"><strong>Policy</strong>: View a list of all seen policies and their respective report counts.</li>
        <li class="p-1 list-group-item border-0"><strong>Policy Builder</strong>: Automatically build new policies based of existing reports.</li>
        <li class="p-1 list-group-item border-0">
          <strong>Inline Reports</strong>: View a list of all inline elements to be moved to their own file.
        </li>

        <li class="p-1 list-group-item border-0"><strong>Policy Evaluator</strong>: Receive security recommendations for your policy.</li>
        <li class="p-1 list-group-item border-0">
          <strong>Alert Settings</strong>: Setup alerts for detecting in realtime attempted XSS's and alert spikes.
        </li>

        <li class="p-1 list-group-item border-0"><strong>Custom Domain</strong>: Setup custom endpoint URLs for receiving reports.</li>

        <li class="p-1 list-group-item border-0"><strong>User Management</strong>: Invite multiple people to a project to inspect reports.</li>
      </ul>
    </div>
  </div>
</div>

<div class="p-3"></div>

<app-wave-top></app-wave-top>
<div class="bg-opacity pb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-6  order-card-2" style="display: table">
        <div class="text-center p-md-3 pb-5" style="vertical-align: middle;
        display: table-cell;">
          <h2 class="pt-4">Ready to get Started?</h2>
          <p class="lead mb-0 pt-3">
            <a class="btn btn-secondary btn-lg mr-2" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }" role="button">Start Free Trial</a>
            <a class="btn btn-outline-primary btn-lg mt-xs-2" [routerLink]="['/pricing']" [queryParams]="{ redirect: '/new' }" role="button">View pricing</a>
          </p>
        </div>
        
      </div>
      <div class="col-md-6 order-card-1">
        <h4 class="text-center py-2">Product Demo</h4>
        <div class="text-center">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe allowfullscreen="" class="embed-responsive-item" src="https://www.youtube.com/embed/E90DeE5R1Yo"></iframe>
          </div>
        </div>
      </div>

    </div>
  </div>

  
</div>
<app-footer></app-footer>
