import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs-page',
  templateUrl: './faqs-page.component.html'
})
export class FaqsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
