import { Component, OnInit } from "@angular/core";
import { AdminService } from "src/app/services/admin.service";
import { Organization } from "src/app/models/organization";

@Component({
  selector: "app-admin-organizations",
  templateUrl: "./admin-organizations.component.html",
  styleUrls: ["./admin-organizations.component.css"],
})
export class AdminOrganizationsComponent implements OnInit {
  organizations: Organization[];

  constructor(private adminService: AdminService) {}

  ngOnInit(): void {
    this.adminService.getOrganizations().subscribe((orgs) => {
      orgs.sort((a, b) => parseInt(b.id, 16) - parseInt(a.id, 16));
      this.organizations = orgs;
    });
  }

  purgeProject(projectID: string) {
    this.adminService.purgeProject(projectID).subscribe(() => {
      console.log("purged");
      // this.ngOnInit();
    });
  }

  purgeOrganization(orgID: string) {
    this.adminService.purgeOrganization(orgID).subscribe(() => {
      console.log("purged");
      // this.ngOnInit();
    });
  }
}
