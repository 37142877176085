import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject, ReplaySubject } from "rxjs";
import { Project, Member } from "../models/project";
import { ConfigService } from "./config.service";
import { Feedback } from "../models/feedback";
import { Policy } from "../models/policy";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.config.getOrigin()}/api/projects`);
  }

  updateProject(p: Project): Observable<Project> {
    return this.http.put<Project>(`${this.config.getOrigin()}/api/projects/${p.id}`, p, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    });
  }

  getProject(id: string): Observable<Project> {
    return this.http.get<Project>(`${this.config.getOrigin()}/api/projects/${id}`);
  }

  getProjectFull(id: string): Observable<Project> {
    return this.http.get<Project>(`${this.config.getOrigin()}/api/projects/${id}/full`);
  }

  newProject(p: Project): Observable<Project> {
    return this.http.post<Project>(`${this.config.getOrigin()}/api/projects`, p);
  }

  inviteMember(projectID: string, Member: Member): Observable<Project> {
    return this.http.post<Project>(`${this.config.getOrigin()}/api/projects/${projectID}/invite`, Member);
  }

  joinProject(projectID, token: string): Observable<Project> {
    return this.http.post<Project>(`${this.config.getOrigin()}/api/projects/${projectID}/join/${token}`, null);
  }

  sendFeedback(feedback: Feedback): Observable<any> {
    return this.http.post(`${this.config.getOrigin()}/api/feedback`, feedback);
  }

  updateRoles(project: Project): Observable<Project> {
    return this.http.put<Project>(`${this.config.getOrigin()}/api/projects/${project.id}/membership`, project);
  }

  deleteMember(projectID: string, Member: Member): Observable<Project> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: Member,
    };

    return this.http.delete<Project>(`${this.config.getOrigin()}/api/projects/${projectID}/membership`, options);
  }

  deleteProject(projectID: string, feedback: Feedback): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: feedback,
    };

    return this.http.delete(`${this.config.getOrigin()}/api/projects/${projectID}`, options);
  }

  getLatestPolicy(policies: Policy[]) {
    return policies.sort((a, b) => {
      return b.version - a.version;
    })[0];
  }
}
