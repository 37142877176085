<div class="pt-65px"></div>
<div class="container py-4">
  <h2 class="text-center pt-md-4">Content Security Policy made easy. Try it free for 14 days.</h2>
  <p class="text-center">
    Spend your time helping and protecting your users, not deciphering violation reports. Csper gives you the tools and insight you need to quickly
    roll out and maintain Content Security Policy. Aggregations, analysis, alerting, and so much more.
  </p>

  <div class="py-2"></div>
  <app-pricing-panels (onPlanSelected)="selectPlan($event)"> </app-pricing-panels>
</div>
<app-wave-one></app-wave-one>
<div class="container py-2">
  <div class="py-md-5"></div>

  <h3 class="text-center pb-3">Detailed Feature View</h3>
  <table class="table table-responsive table-borderless table-hover">
    <tr class="bg-opacity">
      <th width="50%">Feature</th>
      <!-- <th width="12%" class="text-center">Personal</th> -->
      <th width="12%" class="text-center">Basic</th>
      <th width="12%" class="text-center">Business</th>
      <th width="12%" class="text-center">Enterprise</th>
    </tr>

    <tbody>
      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Websites<br /></div>
          <small class="text-muted">Number of websites that can be monitored.</small>
        </td>
        <!-- <td class="text-center text-primary">
          <small><b>1️</b></small>
        </td> -->
        <td class="text-center text-primary">
          <small><b>3</b></small>
        </td>
        <td class="text-center text-primary">
          <small><b>20</b></small>
        </td>
        <td class="text-center text-primary">
          <small><b>20+</b></small>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Robust CSP report-uri endpoint<br /></div>
          <small class="text-muted">Unique URL that can instantly handle 10,000s of reports per second.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Report Aggregation / Grouping<br /></div>
          <small class="text-muted">Similar reports are automatically grouped together and indexed</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Report Classification<br /></div>
          <small class="text-muted">Reports are classified and filtered by type (extension, bot, inline, mixed-content, etc).</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Indexed Report Searching<br /></div>
          <small class="text-muted">Query all reports based off a number of filters include time, policy, directive, classification and more.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Advance CSP analytics<br /></div>
          <small class="text-muted"
            >Insights and aggregations performed on all report groups such as browsers, source locations, timing and more.</small
          >
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Realtime Report View<br /></div>
          <small class="text-muted">View reports the second they are received.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Automatic Policy Generator<br /></div>
          <small class="text-muted">Build new policies with a click of a button based off previous reports.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Policy Evaluations / Feedback<br /></div>
          <small class="text-muted">Receive feedback and recommendations on policies with best practices.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Export Reports (JSON)<br /></div>
          <small class="text-muted">Export the exact reports received by the endpoint.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Full API<br /></div>
          <small class="text-muted">Full API access to dynamically generate projects, policies, reports and more.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr style="background-color: #f1f1fb52">
        <td></td>
        <!-- <td></td> -->
        <td></td>
        <td></td>
        <td></td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Custom Domain<br /></div>
          <small class="text-muted">Reserve your own csper.io report endpoint subdomain.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Threat Detection<br /></div>
          <small class="text-muted">Receive an alert when a malicious report type is received.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Report Spike Alert<br /></div>
          <small class="text-muted">Receive an alert when there's a spike in reports, usually a sign of a misconfigured rollout.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Custom Alert Integration<br /></div>
          <small class="text-muted">Receive notifications to custom tooling and integrations.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr style="background-color: #f1f1fb52">
        <td></td>
        <td></td>
        <!-- <td></td> -->
        <td></td>
        <td></td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">User Management<br /></div>
          <small class="text-muted">Add multiple users to the projects or organization.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">User Roles<br /></div>
          <small class="text-muted">Set user permissions on both the organization and project level.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Organizational View<br /></div>
          <small class="text-muted">Manage many websites on a single dashboard with consolidated billing.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">OAuth Login<br /></div>
          <small class="text-muted">Allow organizational members to log in through OAuth.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">SAML Login<br /></div>
          <small class="text-muted">Allow organization members to log in through SAML.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Custom Invoicing<br /></div>
          <small class="text-muted">Custom invoicing (POs, Invoice links, etc)</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Custom Legal Terms<br /></div>
          <small class="text-muted">Legal review of custom ToS, SLA, DPA, MSA, etc.</small>
        </td>
        <!-- <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td> -->
        <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-danger feather-check" name="x"></i-feather>
        </td>
        <td class="text-center">
          <i-feather class="text-primary feather-check" name="check"></i-feather>
        </td>
      </tr>

      <tr class="shadow-sm">
        <td>
          <div class="margin-minus-8">Support<br /></div>
          <small class="text-muted">We try to help everyone, but support tickets from priority will always come first.</small>
        </td>
        <!-- <td class="text-center text-primary">
          <small><b>Best Effort</b></small>
        </td> -->

        <td class="text-center text-primary">
          <small><b>Limited</b></small>
        </td>
        <td class="text-center text-primary">
          <small><b>Email</b></small>
        </td>
        <td class="text-center text-primary">
          <small><b>Priority</b></small>
        </td>
      </tr>

      <tr class="shadow-sm">
        <th>Pricing</th>
        <!-- <td class="text-center">$7 / month</td> -->
        <td class="text-center">${{ getPlanByTier("BASIC").price }} / month</td>
        <td class="text-center">${{ getPlanByTier("BUSINESS").price }} / month</td>
        <td class="text-center">custom pricing</td>
      </tr>

      <tr class="shadow-sm">
        <th></th>
        <td class="text-center">
          <a class="btn btn-primary btn-sm" routerLink="/new">Get Started</a>
        </td>
        <!-- <td class="text-center">
          <a class="btn btn-primary btn-sm" routerLink="/new">Get Started</a>
        </td> -->
        <td class="text-center">
          <a class="btn btn-primary btn-sm" routerLink="/new">Get Started</a>
        </td>
        <td class="text-center">
          <a class="btn btn-primary btn-sm" routerLink="/demo">Schedule Demo</a>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="container">
    <div class="py-5"></div>
    <h3 class="text-center pb-3">Frequently Asked Questions</h3>
    <app-faqs></app-faqs>
  </div>

  <div class="p-3"></div>

  <div class="text-center p-3 pb-5">
    <h2>Ready to get Started?</h2>
    <p class="lead mb-0 pt-3">
      <a class="btn btn-secondary btn-lg mr-2 mb-1" [routerLink]="['/register']" [queryParams]="{ redirect: '/new' }" role="button"
        >Start Free Trial</a
      >
      <a class="btn btn-outline-primary btn-lg" [routerLink]="['/demo']" role="button">Schedule a demo</a>
    </p>
  </div>
</div>
<app-footer></app-footer>
